import React, { useContext, useEffect, useState } from 'react'
import "./style.scss";
import Navbar from '../../components/Navbar';
import { Accordion, AccordionDetails, AccordionSummary, Box, Container, Grid, IconButton, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '../../components/Button';
import GradientText from '../../components/GradientText';
import CenterBox from '../../components/CenterBox';
import Tabs from '../../components/Tabs';
import { ArrowForwardIos } from '@mui/icons-material';
import ColorBox from '../../components/ColorBox';
import Footer from '../../components/Footer';
import Link from "../../components/Link";
import { AppContext } from '../..';
import SwapImage from '../../assets/images/swapImg.png';
import LeaderBoardImage from '../../assets/images/leaderboardImg.png'
import DexImage from '../../assets/images/dexImg.png'
import XamanIcon from '../../assets/images/xaman2.png'
import CrossIcon from '../../assets/images/cross.png'
import { GoArrowRight, GoArrowDownRight  } from "react-icons/go";
import { BsArrowDownRight } from "react-icons/bs";


const faq = [{
  title: "What is XRPL PAD",
  content: "XRPL PAD is a DeFi platform built on XRP, which allows users to: trade, provide/withdraw/create liquidity, launch and/or participate in XRP based presales."
}, {
  title: "What are Trustlines?",
  content: "In order to be able to have a token on you XRP address, you need to have its trustline enabled. You setting the trustline confirms that you allow this token to be on your wallet."
}, {
  title: "What wallets are compatible?",
  content: "Currently we only support Xaman (mobile wallet) and Crossmark (Chrome Extension)."
}, {
  title: "What is AMM?",
  content: "The AMM amendment on XRP allows the creation of liquidity pools on XRP, which enables easier trading."
}, {
  title: "Can I make passive income?",
  content: "You can provide liquidity to liquidity pools and as a result receive some of the trading fees generated when you withdraw your liquidity."
}, {
  title: "What is impermanent loss?",
  content: "When providing liquidity to a pool, if the price of the tokens fluctuate too much, your liquidity value will be worth less than if you hadn’t provided liquidity. Understand the risk involved before providing liquidity."
}, {
  title: "Any things to look out for?",
  content: "Yes, when trading, look out for the price impact and how much your trade converts."
}, {
  title: "Where to buy XRP?",
  content: "You can buy XRP on most exchanges such as KuCoin."
}
]

export default function Home() {
  const { projectsData } = useContext(AppContext);
  const [projects, setProjects] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState(0);

  useEffect(() => {
    if (projectsData && projectsData.length > 0) {
      const filterProjects = projectsData.filter((project) => project.status === selectedStatus).slice(0, 5);

      setProjects(filterProjects);
    }
  }, [projectsData, selectedStatus]);

  const onTabSelected = (selectedId) => {
    if (selectedId === 0) {
      setSelectedStatus(0);
    } else if (selectedId === 1) {
      setSelectedStatus(1);
    } else if (selectedId === 2) {
      setSelectedStatus(-1);
    }
  }

  return (
    <Box className="home-box">
      <Container className="home-container">
        <Navbar />
        <div className='flex justify-center space-x-40 mt-20'>
          <div className='text-start'>
            <p className='text-poppin text-[64px] font-semibold'>XRPL PAD</p>
            <p className='text-inter text-[32px] font-bold'>DeFi platform built on XRP</p>
            <p className='text-inter text-[20px] font-semibold'>TRADE</p>
            <p className='text-inter text-[20px] font-semibold'>LAUNCH</p>
            <p className='text-inter text-[20px] font-semibold'>INVEST</p>
            <p className='text-inter text-[20px] font-semibold'>BRIDGE</p>
            <p className='text-inter text-[20px] font-semibold'>AND MORE TO COME!</p>    
          </div>
          <div>
            <img src={SwapImage} width={576} height={493} alt='swap'/>
          </div>
        </div>

        <div className='w-full flex space-x-32  py-5 bg-[#E8F4FC] justify-center mt-20'>
          <div>
            <p className='text-poppin text-[70px] font-semibold'>FEATURES</p>
          </div>
          <div className='flex space-x-20 justify-center'>
              <div >
                <p className='text-poppin text-[42px] font-semibold'>3</p>
                <p className='text-inter text-[20px] font-semibold opacity-60'>Core <br/> Components</p>
              </div>
              <div >
                <p className='text-poppin text-[42px] font-semibold'>High</p>
                <p className='text-inter text-[20px] font-semibold opacity-60'>Level Of utility</p>
              </div>
              <div >
                <p className='text-poppin text-[42px] font-semibold'>All-Inclusive</p>
                <p className='text-inter text-[20px] font-semibold opacity-60'>Multiple DeFi aspects</p>
                <p className='text-inter text-[20px] font-semibold opacity-60'>in 1 platform</p>
              </div>
          </div>
        </div>

        <div className='flex justify-center space-x-40 mt-20'>
          <div className='text-start'>
            <p className='text-poppin text-[64px] font-semibold'>LEADERBOARD</p>
            <p className='text-inter text-[24px] font-semibold'>See to top performing XRP Tokens</p>
            <p className='text-inter text-[24px] font-semibold'>Users are able to filter data based on:</p>
            <p className='text-inter text-[24px] font-semibold'>Market Cap</p>
            <p className='text-inter text-[24px] font-semibold'>Volume</p>
            <p className='text-inter text-[24px] font-semibold'>Pool Size</p>
            <div className='mt-6'>
              <a href='/leaderboard' className='rounded-[14px] bg-black text-white font-bold text-[40px] px-4 py-2'>Go to leaderboard</a> 
            </div>
            
          </div>
          <div>
            <img src={LeaderBoardImage} width={800}  alt='swap'/>
          </div>
        </div>

        <div className='w-full flex space-x-32  py-5 bg-[#E8F4FC] justify-center mt-20 items-center'>
          <div>
            <p className='text-poppin text-[70px] font-semibold text-center'>XRP Ecosystem AMM <br/> Stats</p>
          </div>
          <div className='flex space-x-20 justify-center'>
              <div >
                <p className='text-poppin text-[42px] font-semibold'>8.5M</p>
                <p className='text-inter text-[20px] font-semibold opacity-60'>Total XRP <br/> Pooled</p>
              </div>
              <div >
                <p className='text-poppin text-[42px] font-semibold'>616</p>
                <p className='text-inter text-[20px] font-semibold opacity-60'>Active Pool</p>
              </div>
              <div >
                <p className='text-poppin text-[42px] font-semibold'>288</p>
                <p className='text-inter text-[20px] font-semibold opacity-60'>XRP Pairs</p>
              </div>
          </div>
        </div>


        <div className='flex justify-center space-x-40 mt-20'>
          <div className='text-start'>
            <p className='text-poppin text-[64px] font-semibold'>DEX</p>
            <p className='text-inter text-[24px] font-semibold'>Users Can:<br/>Trade different XRP based Cryptos<br/>Provide Liquidity To Liquidity Pools (for apy rewards)</p>
          </div>
          <div>
            <img src={DexImage} width={800}  alt='swap'/>
          </div>
        </div>
        
        <div className='mt-20'>
          <div className=' text-poppin  text-center text-[#23262F] font-semibold'>
            <p className='  text-[70px]'>New to Trading on XRP?</p>
            <p className=' text-[40px]'>We’ll its pretty simple!</p>
            <p className=' text-[40px]'>Here's how:</p>
          </div>
        </div>

        <div className='home-digram-container flex items-center space-x-10'>
          <div className='flex flex-col desc-box p-3'>
            <div className='flex justify-start space-x-6 items-baseline'>
              <p className=' text-poppin text-[36px] text-[#23262F] font-semibold'>1.</p>
              <p className=' text-poppin text-[20px] text-[#23262F] font-semibold'>  Connect your wallet</p>
            </div>            
            <p className=' text-inter text-[16px]'>You can connect with Crossmark or Xaman. Just click  one of your choice on the top right of the screen.</p>
            <div className='flex space-x-1 justify-center mt-4'>
              <img src={XamanIcon} sizes='10' alt='xaman'/>
              <img src={CrossIcon} sizes='10' alt='crossmark'/>
            </div>  
          </div>
          <GoArrowRight size={60}/>
          <div className='flex flex-col desc-box p-3'>
            <div className='flex justify-start space-x-6 items-baseline'>
              <p className=' text-poppin text-[36px] text-[#23262F] font-semibold'>2.</p>
              <p className=' text-poppin text-[20px] text-[#23262F] font-semibold'> Go To Swap</p>
            </div>
            <p className=' text-inter text-[16px]'>Click on the swap button at the very top of the website.</p>
          </div>
          <GoArrowRight size={60}/>
          <div className='flex flex-col desc-box p-3'>
           <div className='flex justify-start space-x-6 items-end'>
              <p className=' text-poppin text-[36px] text-[#23262F] font-semibold leading-[38px]'>3.</p>
              <p className=' text-poppin text-[20px] text-[#23262F] font-semibold leading-[20px]'>Select the cryptos you
              would like to swap</p>
            </div>
            <p className=' text-inter text-[16px] mt-[14px]'>From the pull down button, select the tokens you would like to trade.</p>
          </div>
        </div>

        <div className='w-full home-digram-container flex justify-end relative mt-[50px]'>
          <div className='flex flex-col desc-box p-3'>
           <div className='flex justify-start space-x-6 items-end'>
              <p className=' text-poppin text-[36px] text-[#23262F] font-semibold leading-[38px]'>4.</p>
              <p className=' text-poppin text-[20px] text-[#23262F] font-semibold leading-[20px]'>Setting the trustline</p>
            </div>
            <p className=' text-inter text-[16px] mt-[14px]'>If your wallet does not have your desired token trust ine, the platform will  guide you so you can trade. <br/><br/>If your wallet does have the trustline set up, this step is automatically skipped</p>
          </div>

          <div className='absolute -top-[130px] right-[240px]'>
            <BsArrowDownRight size={120}/>
          </div>

          <div className='absolute -bottom-[90px] right-[540px] rotate-90 '>
            <BsArrowDownRight size={120}/>
          </div>
        </div>

        <div className='w-full home-digram-container flex justify-center '>
          <div className='flex flex-col desc-box p-3'>
           <div className='flex justify-start space-x-6 items-end'>
              <p className=' text-poppin text-[36px] text-[#23262F] font-semibold leading-[38px]'>5.</p>
              <p className=' text-poppin text-[20px] text-[#23262F] font-semibold leading-[20px]'>Enter your amount to swap and click Swap</p>
            </div>
            <p className=' text-inter text-[16px] mt-[14px]'>Enter the amount you would like to trade. If you are happy with the estimated received amount, click Swap Now and confirm transaction in your wallet.</p>
          </div>
        </div>
       <div className=' px-[80px]'>
          <div className='mt-[50px] border-b-[4px] border-black accordion-container-new'>
            <p className='text-center text-[60px] font-bold'>FAQs</p>
            {faq.map(data =>
              <Accordion key={data.title} >
                <AccordionSummary expandIcon={<ExpandMoreIcon color='black' />}>
                  <p className='text-[30px] font-bold'>{data.title}</p>                
                </AccordionSummary>
                <AccordionDetails>
                <p className='text-[30px] font-medium'> {data.content}</p>
                
                </AccordionDetails>
              </Accordion>
            )}
          </div>
        </div>
      </Container>
    </Box >
  )
}
