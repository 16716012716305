import { Backdrop, Box, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import GradientText from '../GradientText'
import Image from '../Image';
import { CopyAll } from '@mui/icons-material';
import Button from "../Button";
import Link from '../Link';
import { formatAddress, formatNumber } from '../Global';
import "./style.scss"
import { toast } from 'react-hot-toast';

export default function Modal({ tokenData = {}, open = false }) {
    const { id, icon, token_name, token_symbol, token_issuer, token_total_supply, soft_cap, hard_cap, token_raised, start_time, end_time, twitter, discord, telegram, medium, website, status } = tokenData;
    const [visible, setVisible] = useState(false);

    const copyAddress = (e) => {
        e.stopPropagation();
        
        navigator.clipboard.writeText(token_issuer);
        toast.success('Copied');
    }

    useEffect(() => {
        setVisible(open);
    }, [open]);

    return (
        visible ?
            <div>
                <Backdrop className='backdrop' open={true} />
                <Box className="modal-wrapper" onClick={() => setVisible(false)} >
                    <Box>
                        <GradientText sizesmall={24} size={60}>
                            Thank you for applying
                        </GradientText>
                        <Typography className='success-text'>You have successfully listed your token</Typography>
                        <Box className="mobile-box">
                            <Image src={icon} className="token-icon" alt="" />
                            <Box className="token-details">
                                <Typography className='token-symbol'>{token_symbol}</Typography>
                                <Typography className='token-name'>{token_name}</Typography>
                                <Typography className='token-address'>
                                    <Box className="token-spec">Token Supply:</Box>
                                    <Box>{formatNumber(token_total_supply)} {token_symbol}</Box>
                                </Typography>
                                <Typography className='token-address'>
                                    <Box className="token-spec">Token issuer:</Box>
                                    <Box>{formatAddress(token_issuer)}</Box>
                                    <CopyAll className='copy-icon' onClick={copyAddress} />
                                </Typography>
                            </Box>
                        </Box>
                        <Link href="/projects">
                            <Button sx={{ margin: "auto", marginTop: "20px" }} text="Explore Projects" />
                        </Link>
                    </Box>
                </Box>
            </div>
            :
            null
    )
}
