import React, { useState } from 'react'
import Navbar from '../../components/Navbar';
import "./style.scss";
import { Box, Container, Tab, Tabs, Typography } from '@mui/material';
import ContainerBox from '../../components/ContainerBox';
import { tokens } from './../../tokens';
import InputCard from '../../components/InputCard';
import SelectPair from '../../components/SelectPair';
import DropDownIcon from "../../assets/images/dropdown.svg";
import CenterBox from '../../components/CenterBox';

export default function Liquidity() {
  const [openSummary, setOpenSummary] = useState(false);
  const [tab, setTab] = useState(0);

  return (
    <Box className="liquidity-box">
      <Navbar />
      <Container className="liquidity-container">
        <ContainerBox className="container-box"
          title={`${tab === 0 ? "Add" : "Remove"} Liquidity`}
          buttonText={`${tab === 0 ? "Add" : "Remove"} Liquidity`}
        >
          <Tabs className='tabs' value={tab} onChange={(e, value) => setTab(value)}>
            <Tab className='tab' label="Add Liquidity" />
            <Tab className='tab' label="Remove Liquidity" />
          </Tabs>
          {tab === 0 ?
            <Box>
              <SelectPair from={tokens['XRP']} to={tokens['ETH']} />
              <InputCard label="Token Pair" token={tokens["ETH"]} displayChain={true} chainColor="#E36B45" />
              <InputCard label="With" token={tokens["XRP"]} displayChain={true} chainColor='#458FE3' disabled />
              <Typography className='reward'>Reward APY: 25%</Typography>
              <CenterBox>
                <Typography
                  sx={{ marginRight: "10px", cursor: 'pointer', color: "rgb(169, 178, 255)" }}
                  onClick={() => setOpenSummary(prev => !prev)}
                >
                  Summary
                </Typography>
                <Box component="img" src={DropDownIcon} alt="dropdown" />
              </CenterBox>
              <CenterBox className={`summary`}>
                <Box className={`summary-item ${openSummary && "h-100"}`}>
                  <Typography>Keyword:</Typography>
                  <Typography>value</Typography>
                </Box>
                <Box className={`summary-item ${openSummary && "h-100"}`}>
                  <Typography>Keyword:</Typography>
                  <Typography>value</Typography>
                </Box>
                <Box className={`summary-item ${openSummary && "h-100"}`}>
                  <Typography>Keyword:</Typography>
                  <Typography>value</Typography>
                </Box>
              </CenterBox>
            </Box>
            :
            <Box>
              <SelectPair from={tokens['XRP']} to={tokens['ETH']} />
            </Box>
          }
        </ContainerBox>
      </Container>
    </Box >
  )
}
