import React, { useContext, useEffect, useState } from 'react';
import { Box, Typography, Backdrop, Modal, Fade, Tooltip, List, ListItem, ListItemAvatar, Avatar, ListItemText, IconButton } from '@mui/material';
import "./style.scss";
import { Close } from '@mui/icons-material';
import { AppContext } from '../..';
import PlaceholderIcon from '../../assets/images/icon-placeholder.svg';
import { formatAddress, formatCurrency, formatNumber, getTokenInfo } from '../Global';

export default function BalanceModal({ open, onClose }) {
    const { balances } = useContext(AppContext);
    const [filterList, setFilterList] = useState([]);

    const getLPDetail = async () => {
        const lp_balances = balances.filter(
            token => token.currency.length > 3 && !token.currency.endsWith("000000000000")
        );

        const lp_details = await Promise.all(lp_balances.map(async (lp) => {
            const tokenInfo = await getTokenInfo(lp.account, lp.currency);
            const assets = Object.values(tokenInfo.assets);
            const balance = Object.values(tokenInfo.obligations);

            let lp_name = "";
            if (assets.length === 1) {
                lp_name = `XRP / ${formatCurrency(assets[0][0].currency)}`
            } else {
                lp_name = `${formatCurrency(assets[0][0].currency)} / ${formatCurrency(assets[1][0].currency)}`;
            }

            return {
                name: lp_name,
                balance: balance[0],
                ...tokenInfo
            }
        }));

        setFilterList(lp_details);
    }

    useEffect(() => {
        getLPDetail();
    }, [balances])

    return (
        <Modal
            className="modal-wrapper"
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={onClose}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
                backdrop: {
                    timeout: 500,
                },
            }}
        >
            <Fade in={open}>
                <Box className="token-modal modal-content">
                    <IconButton className="close-button" onClick={onClose}>
                        <Close />
                    </IconButton>
                    <Typography className='title'>My LP Tokens</Typography>
                    <List className='token-list'>
                        {filterList.length === 0 &&
                            <Box className="error">
                                <Typography className='error-title'>LP Token not found</Typography>
                                <Typography className='error-desc'>
                                    We apologize, but we couldn't find any LP token in your account. Please try again with a different account.
                                </Typography>
                            </Box>
                        }
                        {filterList.map((token, index) =>
                            <ListItem
                                key={index}
                                className='token-item'
                            >
                                <ListItemAvatar>
                                    <Avatar>
                                        <Box
                                            component="img"
                                            src={PlaceholderIcon}
                                            alt="icon"
                                        />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                    className='token-issuer'
                                    primary={token.name}
                                    secondary={
                                        <Tooltip title={token.account}>
                                            <Typography>
                                                {token.account ? formatAddress(token.account) : ""}
                                            </Typography>
                                        </Tooltip>}
                                />
                                <Typography>
                                    {formatNumber(token.balance)}
                                </Typography>
                            </ListItem>
                        )}
                    </List>
                </Box>
            </Fade>
        </Modal>
    )
}
