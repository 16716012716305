import { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { Box } from "@mui/material";
import "./style.scss";
import toast from "react-hot-toast";

const File = ({ square, onChange, ...props }) => {
  const [filePreview, setFilePreview] = useState("");

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    maxFiles: 1,
    multiple: false,
    onDrop: (acceptedFiles) => {
      const fileSizeInKB = acceptedFiles[0].size / 1024;
      if (fileSizeInKB > 100) {
        acceptedFiles[0] = null;
        toast.error("File size exceeds 100kb limit. Please select a smaller file.");
        return;
      }
      setFilePreview(URL.createObjectURL(acceptedFiles[0]));
    },
  });

  useEffect(() => {
    const e = {
      target: {
        name: props.name,
        value: acceptedFiles[0],
      },
    };
    onChange && onChange(e);
  }, [acceptedFiles]);

  return (
    <Box {...props} className={`file-container ${square ? "square" : ""}`}>
      <div {...getRootProps({ className: "dropzone" })}>
        <input {...getInputProps()} />
        {filePreview ? (
          <img className="preview" src={filePreview} alt="Preview" />
        ) : (
          <p className="image-placeholder">Drag and drop an image here, or click to select an image</p>
        )}
      </div>
    </Box>
  );
};

export default File;
