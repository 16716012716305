import React from 'react';
import { Box, Typography, Skeleton } from '@mui/material';
import "./style.scss";
import Image from '../Image';

export default function SelectPair({ from, to }) {
    return (
        <Box className="selectpair-container">
            <Box className="token-info">
                <Typography>
                    Selected Pair:
                </Typography>
                <Box className="token">
                    <Box className="pair-token">
                        {from?.icon ?
                            <Image className="token-icon" src={from?.icon} alt={from?.symbol} /> :
                            <Skeleton variant="circular" width={32} height={32} />}
                        {to?.icon ?
                            <Image className="token-icon" src={to?.icon} alt={to?.symbol} /> :
                            <Skeleton variant="circular" width={32} height={32} />}
                    </Box>
                    <Typography className='symbol'>
                        {from?.symbol || <Skeleton variant="text" width={50} />}
                        /{to?.symbol || <Skeleton variant="text" width={50} />}
                    </Typography>
                </Box>
            </Box>
        </Box >
    )
}
