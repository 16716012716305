import React, { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
// import axios from "axios";

// Example items, to simulate fetching from another resources.

const PaginatedItems = ({ itemsPerPage, getData, totalCount, setComment }) => {
  const [itemOffset, setItemOffset] = useState(0);

  const endOffset = itemOffset + itemsPerPage;
  setComment(`Loading items from ${itemOffset+1} to ${endOffset}`)
  const pageCount = Math.ceil(totalCount / itemsPerPage);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % totalCount;
    getData(newOffset);
    setItemOffset(newOffset);
  };

  return (
    <>
      <ReactPaginate
        breakLabel="..."
        nextLabel=">"
        onPageChange={handlePageClick}
        pageRangeDisplayed={3}
        pageCount={pageCount}
        previousLabel="<"
        renderOnZeroPageCount={null}
        className="react-paginate"
      />
    </>
  );
}

export default PaginatedItems;