export const tokens = {
    'XRP': {
        icon: "https://static-00.iconduck.com/assets.00/xrp-cryptocurrency-icon-2048x2048-2a0bicgj.png",
        symbol: "XRP",
    },
    'ETH': {
        icon: "https://assets.coingecko.com/coins/images/279/large/ethereum.png?1696501628",
        symbol: "ETH",
    },
    'BNB': {
        icon: "https://assets.coingecko.com/coins/images/825/large/bnb-icon2_2x.png?1696501970",
        symbol: "BNB",
    }
}

const tokenMetadata = [
    {
        currency: "XRP",
        issuer: null,
        meta: {
            token: {
                name: "XRP",
                icon: "https://static-00.iconduck.com/assets.00/xrp-cryptocurrency-icon-2048x2048-2a0bicgj.png"
            }
        },
        metrics: {
            price: 0.67
        }
    },
    {
        currency: "AAA",
        issuer: "rHB5v9macx3aQCMSxPEFz3q5XhXmNM6Lgb",
        meta: {
            token: {
                name: "AAA",
            }
        },
        metrics: {
            price: 0.43
        }
    },
    {
        currency: "5553445400000000000000000000000000000000",
        issuer: "rfwzYAmzYFAwy7MmLJdWSy47qfYYAMbin9",
        meta: {
            token: {
                name: "USDT",
                icon: "https://static.xrplmeta.org/icons/USDT.png"
            }
        },
        metrics: {
            price: 1.0
        }
    },
    {
        currency: "5553444300000000000000000000000000000000",
        issuer: "rPsLc5urbzLd5S39MWDo8GfkukqTTvdxvt",
        meta: {
            token: {
                name: "USDC",
                icon: "https://static.xrplmeta.org/icons/USDC.png"
            }
        },
        metrics: {
            price: 1.0
        }
    },
    {
        currency: "BTC",
        issuer: "rBihDa8ioHDEibkF8LvaZhBo7a18prfAet",
        meta: {
            token: {
                name: "Bitcoin",
                icon: "https://static.xrplmeta.org/icons/BTC.png"
            }
        },
        metrics: {
            price: 43614.20
        }
    },
    {
        currency: "ETH",
        issuer: "rL354QwJe6kgmBMeoE1RRoFteyEi1sou89",
        meta: {
            token: {
                name: "ETH",
                icon: "https://static.xrplmeta.org/icons/ETH.png",
            }
        },
        metrics: {
            price: 2281.44
        }
    }
];