import React, {useEffect, useState, useContext, useCallback} from 'react'
import { useAccount, useEnsName } from 'wagmi';
import { Connector, useConnect } from 'wagmi';
import { ethers } from 'ethers';
import sdk from '@crossmarkio/sdk';
import { shortenAddress } from '../../utils';
import Navbar from '../../components/Navbar';
import "./style.scss";
import ContainerBox from '../../components/ContainerBox';
import ETHIcon from '../../assets/images/eth.png'
import MetaMaskIcon from '../../assets/images/metamask.png'
import XamanIcon from '../../assets/images/xaman.png'
import CrossMarkIcon from '../../assets/images/crossmark.png'
import { Box, Container, Typography, Accordion, AccordionSummary, AccordionDetails, Divider, IconButton } from '@mui/material';
import SwitcherIcon from '../../assets/images/switcher.png';
import MetamaskIcon from '../../assets/images/MetamaskIcon.png';
import BridgePng from '../../assets/images/bridge.png'
import toast from 'react-hot-toast';
import { AppContext } from '../..';
import LoadingModal from '../../components/LoadingModal';
import { getCSRFToken, getJWT,  wait } from '../../utils';
import contractABI from '../../ABI/ABI.json'
import { xumm } from "../.."
import { getAccountBalance } from '../../components/Global'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
const fee = 0.1/100

const faq = [{
  title: "What is the  XRPL PAD Launchpad?",
  content: "XRPL PAD Launchpad is a presale platform for project founders to launch, mint and generate liquidity for their new XRP based project."
}, {
  title: "How can we participate in a Presale?",
  content: "Click on the project you are interested in, make sure to read through the description and then enter the amount of xrp you wish to invest (min is 10 XRP). When the presale is over you can claim the tokens and receive on your wallet."
}, {
  title: "What if the presale fails?",
  content: "If the presale fails then the XRP is fully refundable to the buyer."
}, {
  title: "How to list my own project?",
  content: "Click Apply. Fill out the project details and then pay the listing fee of 300 XRP. If presale is successful, XRPL PAD only takes 5% of the total raised."
}, {
  title: "How to get in contact?",
  content: "Below is the link to our Discord server. Join and ask any questions you like!"
}]

const defaultSteps = [
  {
    label: 'Setting Trustline for ETH on XRPL',
    description: `Please confirm the trustline in your wallet.`,
    qr: true,
    confirmInWallet:true
  },
  {
    label: 'Bridging ETH to XRPL',
    description: `We are now bridging from ETH to XRPL. `,
    subMetaDesc: `Please confirm the transaction for ETH in Metamask`
  },
  {
    label: 'Confirming ETH transaction',
    description: `Hold On. Just confirming the transaction...`,
    // tx: true,
  },  
  {
    label: 'Sending to your XRP address ',
    description: `Now sending bridged ETH to your XRP address. Will be received in a few seconds...`,
  },
];

//Steps in converting from XRP to ETH
const otherSteps = [
  {
    label: 'Bridging XRPL to ETH',
    description: 'Please confirm the transaction for ETH in you wallet.',
    qr: true,
    confirmInWallet:true,
    // subXamanDesc: `Please confirm the transaction for ETH in your wallet`,
  },
  {
    label: 'Verifying transaction',
    description: `Verifying your transaction on XRPL.`,
    tx: true,
  },  
  {
    label: 'Confirmed your transaction. ',
    description: `Check your XRP wallet few seconds later.`,
  },
];


const WETH = process.env.REACT_APP_WETH
const WETH_ISSUER = process.env.REACT_APP_WETH_ISSUER
const CONTRACT_ADDRESS = process.env.REACT_APP_CONTRACT_ADDRESS 
const BRIDGE_ADDRESS = process.env.REACT_APP_XRP_BRIDGE_ADDRESS

console.log(WETH, WETH_ISSUER, CONTRACT_ADDRESS, BRIDGE_ADDRESS)

export default function Bridge() {
  const { address, balances, setBalances } = useContext(AppContext);
  const { address: ethAddress } = useAccount()
  const { connectors } = useConnect()
  const [ mMConnector, setMMConnector ] = useState(undefined)
  const [ hasTrustLine, setHasTrustLine] = useState(false)
  const [steps, setSteps] = useState(defaultSteps);
  const [currentStep, setCurrentStep] = useState(0);
  const [open, setOpen] = useState(false);
  const [finalStatus, setFinalStatus] = useState(undefined)
  const [ errorStatus, setErrorStatus] = useState(0)
  const [ethBalances, setEthBalances] = useState({
    eth:0,
    xrp:0
  })
  const [ fromAsset, setFromAsset ] = useState({
    networkIcon: ETHIcon,
    networkName: "Ethereum",
    assetName: "ETH",
    amount: 0.005,
    walletIcon: MetaMaskIcon,
    walletAddress: ethAddress
  })

  const [ toAsset, setToAsset ] = useState({
    networkIcon: "https://static-00.iconduck.com/assets.00/xrp-cryptocurrency-icon-2048x2048-2a0bicgj.png",
    networkName: "XRPL",
    assetName: "WETH",
    amount: (1-fee) * 500,
    walletIcon: localStorage.getItem("XummPkceJwt")?XamanIcon:CrossMarkIcon,
    walletAddress: address
  })

  const checkTrustline = useCallback(() => {
    let existTrustline = false;
    balances.forEach((balance) => {
        if (balance.currency === WETH && balance.account === WETH_ISSUER) {
            existTrustline = true;
            console.log('xrp balance', balance)
            setEthBalances(v=>({...v, xrp:balance.balance}))
            console.log('has')
        }
    });
    return existTrustline

  },[balances])

  useEffect(()=>{
    balances.forEach((balance) => {
      if (balance.currency === WETH && balance.account === WETH_ISSUER) {
          console.log('xrp balance', balance)
          setEthBalances(v=>({...v, xrp:balance.balance}))
          console.log('has')
      }
  });
  },[balances])

  useEffect(()=>{
      console.log('ethAddress', ethAddress)
      console.log('connectors', connectors)
      const mmConnect = connectors.find((connector)=>connector.name =="MetaMask")
      if(!mmConnect){
        toast.error("Can't find Metamask wallet!")
        return
      }
      setMMConnector(mmConnect)
  },[ethAddress, connectors])

  useEffect(()=>{
    if(toAsset.networkName == "XRPL"){
      setToAsset({
        networkIcon: "https://static-00.iconduck.com/assets.00/xrp-cryptocurrency-icon-2048x2048-2a0bicgj.png",
        networkName: "XRPL",
        assetName: "WETH",
        amount: (1-fee) * fromAsset.amount,
        walletIcon: localStorage.getItem("XummPkceJwt")?XamanIcon:CrossMarkIcon,
        walletAddress: address
      })
    }else{
      setFromAsset({
        networkIcon: "https://static-00.iconduck.com/assets.00/xrp-cryptocurrency-icon-2048x2048-2a0bicgj.png",
        networkName: "XRPL",
        assetName: "WETH",
        amount: fromAsset.amount,
        walletIcon: localStorage.getItem("XummPkceJwt")?XamanIcon:CrossMarkIcon,
        walletAddress: address
      })
    }
  },[address])

  useEffect(()=>{
    if(toAsset.networkName == "Ethereum"){
      setToAsset({
        networkIcon: ETHIcon,
        networkName: "Ethereum",
        assetName: "ETH",
        amount: (1-fee) * fromAsset.amount,
        walletIcon: MetaMaskIcon,
        walletAddress: ethAddress
      })
    }else{
      setFromAsset({
        networkIcon: ETHIcon,
        networkName: "Ethereum",
        assetName: "ETH",
        amount: fromAsset.amount,
        walletIcon: MetaMaskIcon,
        walletAddress: ethAddress
      })
    }
    if(ethAddress) getETHBalance(ethAddress)
  },[ethAddress, fromAsset.amount, toAsset.networkName])

  const  getETHBalance = async (ethAddress) => {
    const provider = new ethers.BrowserProvider(window.ethereum)
    const balance = await provider.getBalance(ethAddress);
    console.log('eth balance', balance)
    setEthBalances(v=>({...v, eth: ethers.formatEther(balance)}))
  }

  const handleMM = () => {
    console.log('handleConnectMM')
    if(!mMConnector){
      toast.error("Can't find Metamask wallet!")
      return
    }
    console.log('connecg', mMConnector)
    ethAddress? mMConnector.disconnect() : mMConnector.connect()
  }

  const handleSwitch = () => {
    const fromAssetTmp = {...fromAsset}
    console.log(fromAssetTmp)
    setFromAsset({...toAsset, amount:0})
    setToAsset({...fromAssetTmp, amount:0})
    // setHasTrustLine(v=>!v)
  }

  const handleChangeFromAmount = (newAmount) => {

    setFromAsset(v=>(
      {
        ...v,
        amount: newAmount
      }
    ))

    setToAsset(v=>(
      {
        ...v,
        amount: (1-fee) * newAmount
      }
    ))
    if(newAmount < 0.005) {
      setErrorStatus(2)
      return
    }
    if(newAmount > (fromAsset.assetName==='ETH' ? ethBalances.eth: ethBalances.xrp)){
      setErrorStatus(1)
      return      
    }
 
    setErrorStatus(0)
  }

  const closeLoadingModal = () => {
    setOpen(false);
    setSteps(defaultSteps);
    setCurrentStep(0);
  }

  const createTrustLine = async (cb, amount) => {
    const _crossmark = localStorage.getItem("crossmark");
    if (_crossmark) {
      const _steps = [...steps];
      _steps[0].qr_link = '';
      setSteps(_steps);
      // setCurrentStep(1);

      const { response } = await sdk.methods.signAndSubmitAndWait({
        TransactionType: 'TrustSet',
        Account: address,
        LimitAmount: {
          currency: WETH,
          issuer: WETH_ISSUER,
          value: '10000000000000000'
        }
      });

      if(response.data.meta.isError || response.data.meta.isExpired || response.data.meta.isFail || response.data.meta.isPending || response.data.meta.isRejected) {
        // closeLoadingModal();
        setFinalStatus('failed')
        throw new Error("Transaction Failed.");
      }

      if(response.data.meta.isSuccess) {
        const txid = response.data?.resp?.result?.hash;
        if (txid) {
          toast.success("Trustset was established successfully.");
          const _steps = [...steps];
          _steps[1].qr_link = '';
          setSteps(_steps);
          setCurrentStep(1);
          cb();
        } else {
          closeLoadingModal();
          // setFinalStatus('failed')

          toast.error("Transaction rejected.");
        }
      }

      return response;
    }

    const _xaman = localStorage.getItem("XummPkceJwt");

    if (_xaman) {
      const payload = await xumm.payload?.createAndSubscribe({
        TransactionType: 'TrustSet',
        Account: address,
        LimitAmount: {
          currency: WETH,
          issuer: WETH_ISSUER,
          value: '10000000000000000'
        }
      }, async (event) => {
        if (Object.keys(event.data).indexOf('signed') > -1) {
          if (event.data.expires) {
            // Expires property exists, indicating expiration of sign-in
            closeLoadingModal();
            // setFinalStatus('failed')
            toast.error("Sign-in expired.");
            return;
          } else {
            const txid = event.data.txid;
            if (txid) {
              toast.success("Trustset was established successfully.");
              setCurrentStep(1);
              cb();
              return;
            } else {
              closeLoadingModal();
              toast.error("Transaction rejected.");
              return;
            }
          }
        }
      });
  
      if (payload) {
        xumm.xapp?.openSignRequest(payload.created);
        const _steps = [...steps];
        _steps[0].qr_link = payload.created.refs.qr_png;
        setSteps(_steps);
        // setCurrentStep(1);
      }
  
      return payload;
    }
  }

  const sendETH = async (receiver, amount) => {
    const provider = new ethers.BrowserProvider(window.ethereum)
    console.log(contractABI, 'contractABI')
    const contract = new ethers.Contract(CONTRACT_ADDRESS, contractABI, await provider.getSigner());
    const amountInWei = ethers.parseEther(amount);
    const tx = await contract.lockETH(receiver, { value: amountInWei });
    setCurrentStep(2)
    console.log('Transaction hash:', tx.hash);
    const receipt = await tx.wait();
    const _steps = [...steps];
    _steps[2].tx = tx.hash;
    setSteps(_steps);
    setCurrentStep(3)
    await wait(10);
    setFinalStatus('successed')

    // closeLoadingModal()
  }

  const convertETHToXRP = async () => {

    // await sendETH(address, "0.0001");
    setSteps(defaultSteps)
    setOpen(true)
    if(checkTrustline()){
      setCurrentStep(1)
      try {
        await sendETH(address, fromAsset.amount.toString());
      } catch (err) {
        // closeLoadingModal();
        // toast.error(err.message);
        setFinalStatus('failed')
        console.log(err)
      }
    }else{
      try {
        await createTrustLine(async () => {
          try {
            await sendETH(address, fromAsset.amount.toString());
          } catch (err) {
            // closeLoadingModal();
            setFinalStatus('failed')
            // toast.error(err.message);
            console.log(err)
          }
        }, fromAsset.amount.toString());
      } catch (err) {
        // closeLoadingModal();
        // toast.error(err.message);
        setFinalStatus('failed')
        console.log(err)
      }
    } 
  }

  const convertXRPToETH = async (address) => {
    setSteps([...otherSteps])
    setCurrentStep(0)
    setOpen(true)
    try {
      const tokenAmount = fromAsset.amount.toFixed(6)
      
      const _crossmark = localStorage.getItem("crossmark");
      if (_crossmark) {
        const { response } = await sdk.methods.signAndSubmitAndWait({
          TransactionType: 'Payment',
          Destination: BRIDGE_ADDRESS,
          Account: address,
          Amount : {
            currency : WETH,
            value : tokenAmount,
            issuer : WETH_ISSUER
          },
        });

        if(response.data.meta.isError || response.data.meta.isExpired || response.data.meta.isFail || response.data.meta.isPending || response.data.meta.isRejected) {
          // closeLoadingModal();
          setFinalStatus('failed')
          // toast.error("Transaction Failed.");
          return;
        } else {
          const txid = response.data?.resp?.result?.hash;
          if (txid) {
            const _steps = [...otherSteps];
            _steps[1].subtitle = txid;
            setSteps(_steps);
            setCurrentStep(1);

            try {
              const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/xrp_to_eth`, {
                method: "post",
                headers: {
                  "Content-Type": "application/json",
                  
                  "authorization": `Bearer ${getJWT()}`
                },
                body: JSON.stringify({
                  amount: Number(tokenAmount),
                  address: address,
                  receiver: toAsset.walletAddress,
                  transaction_id: txid
                })
              });

              const data = await response.json();
              setCurrentStep(2)
              await wait(5);
              setFinalStatus('successed')
              // closeLoadingModal();

              if (data?.error) {
                toast.error(data?.error);
                return;
              } else {
                toast.success("Successfully applied");
              }

            } catch (error) {
              // closeLoadingModal();
              setFinalStatus('failed')
              console.log("applying error", error);
              // toast.error(error.message);
              return;
            }
          } else {
            setFinalStatus('failed')
            // toast.error("Transaction rejected.");
            return;
          }
        }
      }

      const _xaman = localStorage.getItem("XummPkceJwt");
      if (_xaman) {
        console.log(BRIDGE_ADDRESS, address, tokenAmount)
        const payload = await xumm.payload?.createAndSubscribe({
          TransactionType: 'Payment',
          Destination: BRIDGE_ADDRESS,
          Account: address,
          Amount : {
            currency : WETH,
            value : tokenAmount,
            issuer : WETH_ISSUER
          },
        }, async (event) => {
          if (Object.keys(event.data).indexOf('signed') > -1) {
            if (event.data.expires) {
              // Expires property exists, indicating expiration of sign-in
              // closeLoadingModal();
              setFinalStatus('failed')
              // toast.error("Sign-in expired.");
              return;
            } else {
              const txid = event.data.txid;
              if (txid) {
                const _steps = [...otherSteps];
                _steps[1].subtitle = txid;
                setSteps(_steps);
                setCurrentStep(1);
                // console.log('toAsset.address', toAsset.walletAddress)
                try {
                  const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/xrp_to_eth`, {
                    method: "post",
                    headers: {
                      "Content-Type": "application/json",
                      
                      "authorization": `Bearer ${getJWT()}`
                    },
                    body: JSON.stringify({
                      amount: Number(tokenAmount),
                      address: address,
                      transaction_id: txid,
                      receiver: toAsset.walletAddress,
                    })
                  });

                  const data = await response.json();
                  setCurrentStep(2)
                  await wait(5);
                  // closeLoadingModal();
                  setFinalStatus('successed')

                  if (data?.error) {
                    toast.error(data?.error);
                    return;
                  } else {
                    toast.success("Successfully applied");
                    return;
                  }

                } catch (error) {
                  // closeLoadingModal();
                  setFinalStatus('failed')
                  console.log("applying error", error);
                  // toast.error(error.message);
                  return;
                }
              } else {
                // closeLoadingModal();
                // toast.error("Transaction rejected.");
                setFinalStatus('failed')
                return;
              }
            }
          }
        });

        if (payload) {
          xumm.xapp?.openSignRequest(payload.created);

          const _steps = [...otherSteps];
          _steps[0].qr_link = payload.created.refs.qr_png;
          setSteps(_steps);
          setCurrentStep(0);
        }

        return payload;
      }
    } catch (err) {
      // closeLoadingModal();
      // toast.error(err.message);
      setFinalStatus('failed')
      console.log(err)
    }
  }

  const handleConvert = async () => {
    if(!ethAddress){
      toast.error("Connect Metamask Wallet.")
      return
    }
    if(!address){
      toast.error("Connect to XRP Wallet")
      return
    }
    const chainId = await mMConnector.getChainId()
    console.log('chainId in get eth balance ', chainId)
    //11155111: Sepolia, 1: Ethereum 
    if(chainId !== 11155111){
      toast.error("Invalid Chain. Switch to Sepolia and try again.")
      return;
    }

    if(fromAsset.assetName === "ETH"){
      await convertETHToXRP()
    }else{
      await convertXRPToETH(address)
    }
  }

  useEffect(()=>{
    if(ethAddress){
      getETHBalance(ethAddress)
    }
  },[])

  return (
    <Box className="bridge-box">
      <Navbar />
      <LoadingModal open={open} steps={steps} currentStep={currentStep}  hasSummary fromAsset={fromAsset} toAsset={toAsset} type='bridge' finalStatus={finalStatus} close={closeLoadingModal} />
      <div className='box-content'>
        <p className='text-[50px] font-bold text-montserrat'>Bridge to ETH</p>
        <p className='text-[27px] font-semibold'>Bridge ETH to and from XRP <br/>New cryptos coming soon!</p>
      </div>
      <div className='w-full flex justify-center mt-[50px]'>
        <div className='box-content w-[60%]  text-white '>
          <button className="flex space-x-10 items-center" onClick={handleMM}>
            <img src={MetamaskIcon} alt='metamask' className='w-[200px]'/>
            {
                ethAddress ? <p className=' font-semibold text-black text-[27px] '> {`Connected: ${shortenAddress(ethAddress)}`}</p>
                :<p className=' font-semibold text-black text-[27px]'>Not Connected</p>
              }
          </button>
          <div className='flex flex-col space-y-3 pb-[20px] mb-8 mt-[40px]'>          
            <div className='flex flex-col space-y-3'>
              <div className='flex justify-between'>
                <div className=' flex flex-col text-black'>
                  <p className='text-[30px] font-bold'>From</p>
                  <div className='flex space-x-2 items-center bg-[#FAFCFE] rounded-md px-2 h-[37px]  '>
                    <img src={fromAsset.networkIcon} alt={'Token logo'} className='w-[30px]'/>
                    <p className='pr-[8px] text-[30px] font-bold'>{fromAsset.networkName === 'Ethereum'?"ETH":"ETH"}</p>
                  </div>
                </div>
                <div className=' flex flex-col text-black'>
                <p className='text-[30px] font-bold'>Send</p>
                  <div className='flex items-center space-x-2 bg-[#F5F5F5] rounded-md w-[300px] px-6'>
                    <input type='number' value={fromAsset.amount} onChange={(e)=>handleChangeFromAmount( Number(e.target.value))} placeholder='Enter' className=' bg-transparent  h-[37px] outline-none border-none w-[50px]' />
                    <p>({fromAsset.assetName==='ETH' ? `ETH`: `ETH`})</p>
                  </div>
                </div>
              </div>
              <div className='flex justify-between text-black'>
                  <p className='font-bold text-[20px]'>Balance: {fromAsset.assetName==='ETH' ? `${ethBalances.eth} ETH`: `${ethBalances.xrp} ETH`}</p>
                  {/* <button className='font-bold text-[20px]' onClick={()=>handleChangeFromAmount(fromAsset.assetName==='ETH' ? ethBalances.eth: ethBalances.xrp)}
                  >MAX</button> */}
          
              </div>
              {/* <div className='flex space-x-2 items-center text-black'>
                <img src={fromAsset.walletIcon} alt={'Token logo'} className=' h-[20px]'/>
                <input type='text' value={fromAsset.walletAddress?fromAsset.walletAddress:""} className=' bg-[#352775]/20 rounded-md px-6 h-[37px] outline-none border-none w-full' />
              </div> */}
            </div>

            <div className='flex justify-center text-black'>
              <button className='flex justify-center items-center w-[30px] h-[30px]' onClick={handleSwitch}>
                <img  src={SwitcherIcon}  alt='Switcher'/>
              </button>
            </div>

            <div className='flex flex-col space-y-3'>
              <div className='flex justify-between'>
                <div className=' flex flex-col text-black'>
                <p className='text-[30px] font-bold'>To</p>
                  <div className='flex space-x-2 items-center bg-[#FAFCFE] rounded-md px-2 h-[37px] border border-[#E8EFFB]'>
                    <img src={toAsset.networkIcon} alt={'Token logo'} className='w-[30px]'/>
                    <p className='pr-[8px] text-[30px] font-bold'>{toAsset.networkName === 'Ethereum'?"ETH":"ETH"}</p>
                  </div>
                </div>
                <div className=' flex flex-col text-black'>
                  <p className='text-[30px] font-bold'>Receive</p>
                  <div className='flex items-center space-x-2 bg-[#F5F5F5] rounded-md w-[300px] px-6'>
                    <input type='number' value={toAsset.amount} className=' bg-transparent  h-[37px] outline-none border-none w-[50px]' />
                    <p>({toAsset.assetName})</p>
                  </div>
                </div>
              </div>
              {/* <div className='flex space-x-2 items-center text-black'>
                <img src={toAsset.walletIcon} alt={'Token logo'} className=' h-[20px]'/>
                <input type='text' value={toAsset.walletAddress?toAsset.walletAddress:""} className=' bg-[#352775]/20 rounded-md px-6 h-[37px] outline-none border-none w-full' />
              </div> */}
            </div>
          </div>
          <div className='flex flex-col space-y-2'>
            <div className='flex justify-between text-black text-[20px] font-semibold'>
              <p>
                Send ({fromAsset.networkName === 'Ethereum'?"ETH":"ETH on XRPL"}):
              </p>
              <p>
                {fromAsset.amount}
              </p>
            </div>
            <div className='flex justify-between text-black text-[20px] font-semibold'>
              <p>
                Receive ({toAsset.networkName === 'Ethereum'?"ETH":"ETH on XRPL"}):
              </p>
              <p>
                {toAsset.amount}
              </p>
            </div>
            <div className='flex justify-between text-black text-[20px] font-semibold'>
              <div>
                <span className='pr-2'>Fee:</span>     
              </div>
              <div>
                0.1%
              </div>
            </div>
            <div className='flex justify-between text-black text-[20px] font-semibold'>
              <p>
                Min Transcation:
              </p>
              <p>
                0.005 ETH
              </p>
            </div>
          </div>

          <div className='flex justify-center mt-4'>
              <button className={`w-fit  px-10 text-[50px] font-bold rounded-lg text-white ${errorStatus===0?'bg-black':'bg-[#CB3333]'}`} onClick={handleConvert}>{errorStatus===1?'Insufficient Balance':(errorStatus===2?'Min Transaction':'Bridge')}</button>
          </div>
        </div>

      </div>
      <div className='box-content mt-[50px]'>
        <p className='text-[50px] font-bold text-montserrat'>Bridge Explained</p>
        <div className='body-box intro grid grid-cols-5 mb-[50px]'>
          <div className='col-span-3'>
            <div className='grid grid-cols-2 h-[600px]'>
              <div className='flex flex-col place-content-between '>
                <div className='desc-item'> 
                  <p className='title'>
                  Utility
                  </p>
                  <p className='desc'>
                  The allows users to bridge non XRP cryptos such as BSC to and from XRP.<br/><br/>You can then use these bridged tokens to trade on  XRP.FINANCE or others.
                  </p>
                </div>
                <div className='desc-item'> 
                  <p className='title'>
                  Community Friendly
                  </p>
                  <p className='desc'>
                  Whilst most bridged tokens on XRP have a transfer fee (usually 0.3%) ours does not have any transfer fee.<br/><br/>If a token has a transfer fee of 0.3% and a pool has a trading fee of 0.3% as well, the total fee you would pay is 0.6% (plus possible slippage). With our bridged tokens, the would be only 0.3%  trading fee.
                  </p>
                </div>
              </div>
              <div className='flex flex-col  place-content-between'>
                <div className='desc-item'> 
                  <p className='title'>
                  Fast
                  </p>
                  <p className='desc'>
                  Typically the process to convert will take at most 3 mins, but as you get used to the platform, it will take less than a minute!
                  </p>
                </div>
                <div className='desc-item'> 
                  <p className='title'>
                    Safe
                  </p>
                  <p className='desc'>
                  The bridged tokens have freeze disabled, therefore, we can never freeze the tokens, which is a huge plus for users.
                  </p>
                </div>
                <div className='desc-item'> 
                  <p className='title'>
                  Passive Income
                  </p>
                  <p className='desc'>
                  With these bridged tokens, you can create liquidity pool by providing liquidity and then receive passive income from the trading fees
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className='col-span-2'>
            <div>
              <img src={BridgePng} width={500} height={500} alt='bridge'/>
            </div>
          </div>
        </div>
      </div>

      <div className='box-content mt-[50px]'>
        <p className='text-[50px] font-bold text-montserrat'>How to use</p>
        <div>
          <p className='text-[30px] font-medium'>BSC to XRPL</p>
          <div className='flex justify-around'>
            <div className='desc-item'> 
              <p className='title  !text-[18px] leading-5'>
              Go above and select the option for BSC to XRP
              </p>
            </div>
            <div className='desc-item'> 
              <p className='title  !text-[18px] leading-5'>
              Enter the values of how much you want to bridge to XRPL 
              </p>
            </div>
            <div className='desc-item'> 
              <p className='title  !text-[18px] leading-5'>
              Click Bridge and follow the instructions that will pop up
              </p>
            </div>
            <div className='desc-item !bg-[#E0FFDB]'> 
              <p className='title  !text-[18px] leading-5'>
              Receive Bridged tokens on your XRP wallet!
              </p>
            </div>
          </div>
        </div>
        <div className='my-[50px]'>
          <p className='text-[30px] font-medium'>XRPL to BSC</p>
          <div className='flex justify-around'>
            <div className='desc-item'> 
              <p className='title  !text-[18px] leading-5'>
              Go above and select the option for BSC to XRP
              </p>
            </div>
            <div className='desc-item'> 
              <p className='title  !text-[18px] leading-5'>
              Enter the values of how much you want to bridge to XRPL 
              </p>
            </div>
            <div className='desc-item'> 
              <p className='title  !text-[18px] leading-5'>
              Click Bridge and follow the instructions that will pop up
              </p>
            </div>
            <div className='desc-item !bg-[#E0FFDB]'> 
              <p className='title  !text-[18px] leading-5'>
              Receive ETH on your Metamask wallet!
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className='box-content mt-[50px]'>
      <div className='mt-[50px] border-b-[4px] border-black accordion-container-new'>
            <p className='text-center text-[60px] font-bold'>FAQs</p>
            {faq.map(data =>
              <Accordion key={data.title} >
                <AccordionSummary expandIcon={<ExpandMoreIcon color='black' />}>
                  <p className='text-[30px] font-bold'>{data.title}</p>                
                </AccordionSummary>
                <AccordionDetails>
                <p className='text-[30px] font-medium'> {data.content}</p>
                
                </AccordionDetails>
              </Accordion>
            )}
          </div>
      </div>
    </Box >
  )
}
