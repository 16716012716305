import moment from "moment";
import axios from 'axios';
import { zeroPad } from "react-countdown";
import PlaceholderIcon from '../../assets/images/icon-placeholder.svg';
import toast from "react-hot-toast";
import { getCSRFToken, getJWT } from "../../utils";

export const formatNumber = (num = 0) => {
    const parsedNum = parseFloat(num);

    if (isNaN(parsedNum)) {
        return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    return parsedNum.toLocaleString();
}

export const formatAddress = (address) => {
    if (address) {
        let shortenedStr = address.substring(0, 4) + " ... " + address.substring(address.length - 4);
        return shortenedStr;
    }

    return "Invalid Address";
}

export const formatTransaction = (address) => {
    if (address) {
        let shortenedStr = address.substring(0, 8) + " ... " + address.substring(address.length - 8);
        return shortenedStr;
    }

    return "Invalid Transaction ID";
}

export const formatCurrency = (code) => {
    if (!code) return "???";
    if (!/(00)+$/.test(code)) {
        return code;
    }

    code = code.replace(/(00)+$/, '');

    let result = '';
    for (let i = 0; i < code.length; i += 2) {
        const asciiCode = parseInt(code.substring(i, i + 2), 16);
        if (asciiCode <= 0 || asciiCode > 127) {
            break;
        }
        result += String.fromCharCode(asciiCode);
    }

    return result;
}

function isValidHash(hash) {
    return typeof hash === 'string' && /^[0-9A-Fa-f]{40}$/.test(hash);
}

export const textToAsciiHexString = (text) => {
    if (text.length < 4) return text;

    if (isValidHash(text)) {
        return text.toUpperCase();
    }

    if (!text) return "???";

    let hexString = '';
    for (let i = 0; i < text.length; i++) {
        const asciiCode = text.toUpperCase().charCodeAt(i);
        hexString += asciiCode.toString(16).padStart(2, '0');
    }

    if (hexString.length > 40) {
        hexString = hexString.substring(0, 40);
    } else {
        hexString = hexString.padEnd(40, '0');
    }

    return hexString.toUpperCase();
}

export const getStatus = (status) => {
    switch (status) {
        case 0:
            return "Upcoming";
        case 1:
            return "On Going";
        case -1:
            return "Ended";
        default:
            return "Upcoming";
    }
}

export const checkProjectSuccess = (status, soft_cap, token_raised) => {
    if (status === -1 && token_raised / 1 >= soft_cap / 1) {
        return true;
    }

    return false;
}

export const xrpToDrops = (xrp) => {
    return xrp * 1000000;
}

export const dropsToXrp = (drops) => {
    return drops / 1000000;
}

export const convertDateString = (dateString) => {
    const isoDate = moment(dateString).format("YYYY-MM-DD HH:mm:ss")
    return new Date(isoDate);
}

export const getTimeStatusText = (status, success) => {
    if (status === -1) {
        return success ? "Success" : "Ended";
    }

    if (status === 0) {
        return "Starts In";
    }

    if (status === 1) {
        return "Ends In";
    }
}

export const CountdownRender = ({ days, hours, minutes, seconds, completed}) => {
    return <div className='countdown'>{zeroPad(days)} : {zeroPad(hours)} : {zeroPad(minutes)} : {zeroPad(seconds)}</div>;
};

export const isStarted = (dateString) => {
    if (!dateString) return false;
    const now = moment().utc();
    const start = moment(dateString).utc();
    return now.isAfter(start);
}

export const getTokenIcon = (token) => {
    return token?.tokenImage || token?.meta?.token?.icon || PlaceholderIcon;
}

export const getTokenName = (token) => {
    return formatCurrency(token.currency);
}

export const getTokenPrice = (token) => {
    return token?.metrics?.price || 0;
}

export const tokenToJson = (token, amount) => {
    return token.account ? {
        currency: token.currency,
        value: amount,
        issuer: token.account
    } : xrpToDrops(amount).toString();
}

export const tokenToInfoJson = (token) => {
    return token.account ? {
        currency: token.currency,
        issuer: token.account
    } : {
        currency: "XRP"
    };
}

export const getAccountBalance = async (address) => {
    try {
        const res = await fetch(`${process.env.REACT_APP_SERVER_URL}/account/${address}`);
        const account = await res.json();
        return account.result || [];
    } catch (e) {
        console.log(e);
        toast.error(e.message || e);
    }
}

export const calculateLPTokenAmount = (pool, assetA, assetB) => {
    if (!pool || !assetA || !assetB) return 0;

    const totalLPTokens = pool.lp_token.value;
    const totalAssetA = tokenMetaToAmount(pool.amount);
    const totalAssetB = tokenMetaToAmount(pool.amount2);

    const lpTokensReceived = totalLPTokens * Math.min(assetA / totalAssetA, assetB / totalAssetB);

    return lpTokensReceived.toFixed(0);
}

export const calculateLPToAmount = (pool, assetA) => {
    if (!pool || !assetA) return 0;

    const totalAssetA = tokenMetaToAmount(pool.amount);
    const totalAssetB = tokenMetaToAmount(pool.amount2);

    const tokenToDeposit = (assetA * totalAssetB) / totalAssetA;

    return tokenToDeposit.toFixed(5);
}

export const calculateLPFromAmount = (pool, assetB) => {
    if (!pool || !assetB) return 0;

    const totalAssetA = tokenMetaToAmount(pool.amount);
    const totalAssetB = tokenMetaToAmount(pool.amount2);

    const tokenToDeposit = (assetB * totalAssetA) / totalAssetB;

    return tokenToDeposit.toFixed(5);
}

export const tokenMetaToAmount = (token) => {
    if (!token) return 0;

    if (token.value) {
        return (token.value / 1).toFixed(3);
    }

    return (dropsToXrp(token) / 1).toFixed(3);
}

export const tokenMetaToCurrency = (token) => {
    if (!token) return "-";

    if (token.currency) {
        return formatCurrency(token.currency);
    }

    return "XRP";
}

export const getAMMInfo = async (token1, token2) => {
    const jsonToken1 = tokenToInfoJson(token1);
    const jsonToken2 = tokenToInfoJson(token2);
    await delay(2);
    const res_info = await fetch(`${process.env.REACT_APP_SERVER_URL}/amm`, {
        method: "post",
        headers: {
            "Content-Type": "application/json",
            
            "authorization": `Bearer ${getJWT()}`
        },
        body: JSON.stringify({
            asset: jsonToken1,
            asset2: jsonToken2
        })
    });

    const amm_info = await res_info.json();
    return amm_info;
}

export const getTokenInfo = async (issuer, currency) => {
    const res_info = await fetch(`${process.env.REACT_APP_SERVER_URL}/token?issuer=${issuer}&currency=${currency}`);

    const token_info = await res_info.json();
    return token_info.result;
}

export const calculateSwapAmount = async (amm, from_amount) => {
    if (!amm) return;

    const totalAsset1 = tokenMetaToAmount(amm.amount);
    const totalAsset2Value = tokenMetaToAmount(amm.amount2);
    console.log('totalAsset1', totalAsset1)
    console.log('totlalAsset2', totalAsset2Value)
    const K = parseFloat(totalAsset1) * parseFloat(totalAsset2Value);
    // Apply the trading fee to the amount being swapped to find the effective amount for swapping
    const effectiveFromAmount = from_amount * (1 - (parseFloat(amm.trading_fee) / 100000));
    // If initial amount of token 1 (totalAsset1) is not 0
    if (Math.abs(totalAsset1) > Number.EPSILON) {
        // Calculate the new total of Asset1 in the pool after adding the effective swapped amount
        const newTotalAsset1 = parseFloat(totalAsset1) + effectiveFromAmount;
        // Use the constant product formula to find the new total value of Asset2
        const newTotalAsset2Value = K / newTotalAsset1;
        // Calculate the amount of Asset2 received from the swap before applying the fee to the output
        let asset2Received = parseFloat(totalAsset2Value) - newTotalAsset2Value;
        // Return the calculated amount of Asset2 received, rounded to 5 decimal places
        // The trading fee has already been applied to the input amount, so no further fee adjustment is needed here
        return asset2Received.toFixed(6).replace(/(\.\d*?[1-9])0+$/, '$1');
    } else {
        const totalAsset2ValueAsNumber = parseFloat(totalAsset2Value);
        return totalAsset2ValueAsNumber.toFixed(totalAsset2ValueAsNumber > 1 ? 2 : 0);
    }
}


export const showResponseResult = (response) => {
    if (!response) {
        toast.error("Transaction result is not correct.");
        return false;
    }

    if (response.data.meta.isError) {
        toast.error(response.data.errorMessage);
        return false;
    }

    if (response.data.meta.isRejected) {
        toast.error("Transaction rejected.");
        return false;
    }

    if (response.data.meta.isExpired) {
        toast.error("Transaction expired.");
        return false;
    }

    if (response.data.meta.isPending) {
        toast.error("Transaction is pending.");
        return false;
    }

    if (response.data.meta.isFail) {
        toast.error("Transaction is failed.");
        return false;
    }

    if (response.data.meta.isSigned && response.data.meta.isSuccess) {
        // toast.success("Transaction is successful.");
        return true;
    }

}

export const delay  = async(secs) => {
    return new Promise((resolve)=>{
        setTimeout(() => {
            resolve()
        }, secs*1000);
    })
}

export const getUSPriceFromXRP = async (xrpAmount) => {
    if(!xrpAmount) return 0
    console.log('xrpAmount', xrpAmount)
    const { data } = await axios.get(`${process.env.REACT_APP_SERVER_URL}/price`)
    console.log('xrpPrice', data.result)
    return Number(data.result) * Number(xrpAmount)
}