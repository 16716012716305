import React, { useContext, useEffect, useState } from 'react'
import Navbar from '../../components/Navbar';
import { Box, Button, Container, IconButton, InputAdornment, TextField, Typography } from '@mui/material';
import GradientText from '../../components/GradientText';
import "./style.scss";
import CenterBox from '../../components/CenterBox';
import { Close, Edit, Telegram, Twitter, YouTube } from '@mui/icons-material';
import ApplyButton from "../../components/Button";
import Discord from "../../assets/images/discord-fill.svg"
import Medium from "../../assets/images/medium-fill.svg"
import Website from "../../assets/images/website-fill.svg"
import KYCFOLLOW from "../../assets/images/kyc_follow.png"
import KYCVERIFIED from "../../assets/images/kyc_verifed.png"


import Image from '../../components/Image';
import Modal from '../../components/Modal';
import { AppContext } from '../..';
import { xumm } from "../.."
import { toast } from 'react-hot-toast';
import moment, { now } from 'moment';
import FileUpload from '../../components/FileUpload';
import LoadingModal from '../../components/LoadingModal';
import KycModal from '../../components/KycModal';
import Liquidity from '../liquidity';
import axios from "axios";
import Link from "../../components/Link";
import Checkbox from '@mui/material/Checkbox';
import { escapeHTML, getJWT } from '../../utils';
import sanitizeHtml from 'sanitize-html';

export default function Apply() {
  const { address } = useContext(AppContext);

  const defaultSteps = [
    {
      label: 'Confirming Payment',
      description: <p>Please confirm the listing fee transaction. <br/><br/> Check notifications from Xaman, Event Requests in app, or scan the QR code with Xaman.</p>,
      qr: true,
    },
    {
      label: 'Uploading project data',
      description:
        `Uploading the project info to the platform.`,
    },
    {
      label: 'Verifying Transaction',
      description: <p>Just quickly verifying the transaction. <br/> Hold on, we are almost there!</p>,
      tx: true,
    },
    {
      label: 'Creating a trustline',
      description:<p>Create the trustline for your new token. <br/> <br/> Check notifications from Xaman, Even Requests in app, or scan the QR code with Xaman</p>,
      qr: true,
    },
  ];

  const [open, setOpen] = useState(false);
  const [showError, setShowError] = useState(false);
  const [result, setResult] = useState({});
  const [steps, setSteps] = useState(defaultSteps);
  const [currentStep, setCurrentStep] = useState(0);
  const [openLoading, setOpenLoading] = useState(false);
  const [openKycLoading, setOpenKycLoading] = useState(false);
  const [checked, setCheck] = useState(false);
  const [finalStatus, setFinalStatus] = useState(undefined)

  const [percentages, setPercentages] = useState([
    {
      title: "Public",
      value: 40,
      disabled: true
    },
    {
      title: "Liquidity",
      value: 20,
      disabled: true
    },
  ]);

  const [error, setError] = useState({
    projectname: {
      value: "",
      required: true,
    },
    description: {
      value: "",
      required: true,
    },
    distribution: {
      value: "",
    },
    icon: {
      value: "",
      required: true,
    },
    tokenname: {
      value: "",
      required: true,
    },
    tokensymbol: {
      value: "",
      required: true,
    },
    totalsupply: {
      value: "",
      required: true,
    },
    softcap: {
      value: "",
      required: true,
    },
    hardcap: {
      value: "",
      required: true,
    },
    xrp_to_lp: {
      value: "",
      required: true,
    },
    startdate: {
      value: "",
      required: true,
    },
    enddate: {
      value: "",
      required: true,
    },
    website: {
      value: "",
      required: true,
    },
  });

  const [values, setValues] = useState({
    projectname: "",
    description: "",
    distribution: "",
    icon: "",
    tokenname: "",
    tokensymbol: "",
    totalsupply: "",
    softcap: "",
    hardcap: "",
    xrp_to_lp: "",
    startdate: "",
    enddate: "",
    website: "",
  });

  const label = { inputProps: { 'aria-label': 'terms check box' } };

  const onChange = (e) => {
    const file = e.target.value;
    if (file?.path) {
      const fileSizeInKB = file.size / 1024;
      if (fileSizeInKB > 100) {
        toast.error('File size exceeds 100kb limit. Please select a smaller file.');
        e.target.value = null;
        return;
      }
      if(!['image/jpeg', 'image/png'].includes(file.type)){
        toast.error('Invalid file type.');
        e.target.value = null;
        return;
      }     
      setValues({ ...values, [e.target.name]:e.target.value });
      return
    }
    console.log('sanitizeHtml(e.target.value) ', sanitizeHtml(e.target.value) )
    setValues({ ...values, [e.target.name]: e.target.name==='totalsupply' || e.target.name==='softcap' || e.target.name==='hardcap' ?  Number(sanitizeHtml(e.target.value)) :sanitizeHtml(e.target.value)  });
  };

  const createTrustline = async (from, tokenSymbol, issuer, tokenAmount) => {
    const payload = await xumm.payload?.createAndSubscribe({
      TransactionType: 'TrustSet',
      Account: from,
      LimitAmount: {
        currency: tokenSymbol,
        issuer: issuer,
        value: tokenAmount.toString(),
      }
    }, async (event) => {
      if (Object.keys(event.data).indexOf('signed') > -1) {
        const txid = event.data.txid;
        if (txid) {
          try {
            console.log(txid);
            // toast.success("TrustLine established.");
            setFinalStatus('successed')
            // closeLoadingModal()
            setOpen(true);
            return txid;
          } catch (error) {
            // closeLoadingModal()
            setFinalStatus('failed')
            console.log(error.message);
            return;
          }
        } else {
          setFinalStatus('failed')
          console.log('trustline rejected');
          return;
        }
      }
    });

    if (payload) {
      xumm.xapp?.openSignRequest(payload.created);

      const _steps = [...steps];
      _steps[3].qr_link = payload.created.refs.qr_png;
      setSteps(_steps);
    }
  };

  useEffect(() => {
    showError && validateForm();
  }, [values, showError, percentages]);

  const apply = async (transaction_id, from_address, amount) => {
    const reqData = {
      transaction_id,
      from_address,
      to_address: process.env.REACT_APP_XRP_TEAM_ADDRESS,
      amount: Number(amount),
      values: { ...values },
      flag: 1, // devnet flag
    };

    const formData = new FormData();
    formData.append('icon', values.icon);
    console.log('values icon', values.icon)

    reqData.values.startdatetime = moment(values.startdate).utc().format();
    reqData.values.enddatetime = moment(values.enddate).utc().format();

    setCurrentStep(1);

    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/upload`, {
      method: "post",
      headers:{
        "authorization": `Bearer ${getJWT()}`
      },
      body: formData
    });
    const files = await response.json();

    reqData.values.icon = files.result.icon;

    reqData.values.distribution[0].title = "Public";

    const _steps = [...steps];
    _steps[2].subtitle = transaction_id;
    setSteps(_steps);
    setCurrentStep(2);

    const responseApply = await fetch(`${process.env.REACT_APP_SERVER_URL}/apply`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        
        "authorization": `Bearer ${getJWT()}`
      },
      body: JSON.stringify(reqData)
    });
    const data = await responseApply.json();
    return data;
  }

  const onSubmit = async (e) => {
    if (!checked) {
      toast.error("Please check the terms and conditions.");
      return;
    }
    try {
      const validForm = validateForm();
      setShowError(!validForm);

      if (!validForm) {
        console.log("Invalid fields.", error)
        toast.error("Invalid fields.");
        return;
      }

      if (!address) {
        toast.error("Please connect Xumm wallet.");
        return;
      }

      const _crossmark = localStorage.getItem("crossmark");
      if (_crossmark) {
        toast.error("You must use the Xumm wallet.");
        return;
      }

      // const result = await axios.get(`https://xumm.app/api/v1/platform/kyc-status/${address}`);
      // if (!result.data.kycApproved) {
      //   toast.error("You must use a verified wallet.");
      //   setOpenKycLoading(true);
      //   return;
      // }

      setCurrentStep(0);
      setOpenLoading(true);

      const tokenAmount = (300 * 10 ** process.env.REACT_APP_XRP_EXPONENT).toString();
      

      const payload = await xumm.payload?.createAndSubscribe({
        TransactionType: 'Payment',
        Destination: process.env.REACT_APP_XRP_TEAM_ADDRESS,
        Account: address,
        Amount: tokenAmount,
      }, async (event) => {
        if (Object.keys(event.data).indexOf('signed') > -1) {
          if (event.data.expires) {
            // Expires property exists, indicating expiration of sign-in
            setFinalStatus('failed')
            // closeLoadingModal()
            // toast.error("Sign-in expired.");
            return;
          } else {
            const txid = event.data.txid;
            if (txid) {
              try {
                const res = await apply(txid, address, tokenAmount);

                if (res.status === 200) {
                  // toast.success("Transaction completed.");

                  const project = res.result.project;
                  // console.log("res", project)
                  setResult(project);

                  const _steps = [...steps];
                  _steps[3].subtitle = `Adding ${project.token_symbol} token`;
                  setSteps(_steps);
                  setCurrentStep(3);

                  await createTrustline(address, project.token_symbol, project.token_issuer, project.token_total_supply);
                 
                  // console.log("trust_res", trust_res)
                } else {
                  // closeLoadingModal()
                  setFinalStatus('failed')

                  // toast.error("Invalid parameters.");
                  // console.log(res);
                  return;
                }
              } catch (error) {
                // closeLoadingModal()
                setFinalStatus('failed')

                console.log("applying error", error);
                // toast.error(error.message);
                return;
              }
            } else {
              setFinalStatus('failed')
              // closeLoadingModal()
              // toast.error("Transaction rejected.");
              return;
            }
          }
        }
      });

      if (payload) {
        xumm.xapp?.openSignRequest(payload.created);

        const _steps = [...steps];
        _steps[0].qr_link = payload.created.refs.qr_png;
        setSteps(_steps);
      }

      return payload;
    } catch (err) {
      // closeLoadingModal()
      setFinalStatus('failed')
      // toast.error(err.message);
      console.log(err)
    }
  };

  const validateForm = () => {
    let valid = true;

    Object.keys(error).map((key) => {
      if (!values[key] && error[key].required) {
        setError((prev) => ({ ...prev, [key]: { ...prev[key], value: "Field required." } }));
        valid = false;
      } else {
        setError((prev) => ({ ...prev, [key]: { ...prev[key], value: "" } }));
      }
    });

    if (!/^[A-Z]{3}$/.test(values["tokensymbol"])) {
      setError((prev) => ({
        ...prev,
        ["tokensymbol"]: { ...prev["tokensymbol"], value: "Token Symbol must be 3 capital letters." },
      }));
      valid = false;
    }

    if (isNaN(values["hardcap"]) || isNaN(values["softcap"]) || values["hardcap"] < values["softcap"] * 1.25) {
      setError((prev) => ({
        ...prev,
        ["hardcap"]: { ...prev["hardcap"], value: "Hard Cap must be minimum 25% higher than Soft Cap." },
      }));
      valid = false;
    }

    const now = moment();
    if (!moment(values["startdate"]).isAfter(now)) {
      setError((prev) => ({
        ...prev,
        ["launchtime"]: { ...prev["launchtime"], value: "Please enter correct start date." },
      }));
      valid = false;
    }

    if (!moment(values["enddate"]).isSameOrAfter(moment(values["startdate"]).add(3, 'days'))) {
      setError((prev) => ({
        ...prev,
        ["launchtime"]: { ...prev["launchtime"], value: "End date should be at least 3 days after start date." },
      }));
      valid = false;
    }

    if (percentages.reduce((acc, item) => acc + parseInt(item?.value || 0), 0) !== 100) {
      setError((prev) => ({
        ...prev,
        distribution: { ...prev.distribution, value: "Distribution percentages must add up to 100%." },
      }));
      valid = false;
    }

    for (const item of percentages) {
      if (!item.title) {
        setError((prev) => ({
          ...prev,
          distribution: { ...prev.distribution, value: "Field required." },
        }));
        valid = false;
      }
    };

    return valid;
  };

  const onAddDistribution = () => {
    setPercentages((prev) => {
      setValues((prevValues) => ({
        ...prevValues,
        distribution: [...prev, { title: "", value: 0 }]
      }));

      return [...prev, { title: "", value: 0 }]
    });
  };

  const onRemovePercentage = (i) => {
    if (i === 0) {
      toast.error("Can't delete Public distribution.");
      return;
    } else if (i === 1) {
      toast.error("Can't delete Liquidity distribution.");
      return;
    }

    setPercentages((prev) => {
      let newValue = [...prev];
      newValue.splice(i, 1);

      setValues((prevValues) => ({
        ...prevValues,
        distribution: newValue
      }));

      return newValue;
    });
  }

  const onChangePercentageTitle = (i, value) => {
    setPercentages((prev) => {
      const newValue = [...prev];
      newValue[i] = {
        title: value,
        value: newValue[i].value
      }

      setValues((prevValues) => ({
        ...prevValues,
        distribution: newValue
      }));

      return newValue;
    });
  }

  const onChangePercentageValue = (i, value) => {
    const newPercentages = [...percentages];
    const intValue = parseInt(value);

    if (i === 1) { // Index 1 corresponds to the "Liquidity" distribution
      if (intValue < 20) {
        newPercentages[i].value = 20; // Set to minimum 20%
      } else if (intValue > 90) {
        newPercentages[i].value = 90; // Set to maximum 90%
      } else {
        newPercentages[i].value = intValue;
      }
    } else {
      newPercentages[i].value = intValue;
    }

    setPercentages((prev) => {
      const newValue = [...prev];
      newValue[i] = {
        title: newValue[i].title,
        value: value
      }

      setValues((prevValues) => ({
        ...prevValues,
        distribution: newValue
      }));
      return newValue;
    });
  }

  const closeLoadingModal = () => {
    setOpenLoading(false);
    setSteps(defaultSteps);
    setCurrentStep(0);
  }

  const closeKycModal = () => {
    setOpenKycLoading(false);
  }

  const handleChange = () => {
    setCheck(!checked);
  }

  // Function to convert local date and time to UTC
  function convertToUTC(dateTimeString) {
    // Create a Date object with the local date and time
    const localDate = new Date(dateTimeString);

    // Get the UTC date and time components
    const utcYear = localDate.getUTCFullYear();
    const utcMonth = localDate.getUTCMonth() + 1; // Months are zero-based, so we add 1
    const utcDay = localDate.getUTCDate();
    const utcHours = localDate.getUTCHours();
    const utcMinutes = localDate.getUTCMinutes();
    const utcSeconds = localDate.getUTCSeconds();

    // Format the UTC date and time string
    const utcDateTime = `${utcYear}-${padZero(utcMonth)}-${padZero(utcDay)} ` +
      `${padZero(utcHours)}:${padZero(utcMinutes)}:${padZero(utcSeconds)}`;

    return utcDateTime;
  }

  // Helper function to pad single-digit numbers with leading zero
  function padZero(number) {
    return number < 10 ? '0' + number : number;
  }

  return (
    <Box className="apply-box">
      <Navbar />
      <Modal tokenData={result} open={open} />
      <LoadingModal open={openLoading} steps={steps} currentStep={currentStep} finalStatus={finalStatus} type="launch" data = {result} />
      {/* <KycModal open={openKycLoading} closeModal={closeKycModal} /> */}

      {
        !openKycLoading?      
        <div className='content-panel'>
          <div className='title-box'>
            <p className='title'>Launch a presale</p>
            <p className='sub-title mt-4'>To launch a presale, please fill out this form <br/> and pay the 300 XRP listing fee.</p>
          </div>
          <p className='text-[40px] text-center font-bold text-montserrat'>Project details</p>
          <Box sx={{ marginTop: "50px" }}>
            <p className='text-[30px] font-bold'>{escapeHTML('Project Name *')}</p>
            <TextField
              helperText={showError && error.projectname.value}
              name="projectname"
              value={values.projectname}
              onChange={onChange}
              placeholder={escapeHTML('Project Name *')}
              hiddenLabel
              required
            />
          </Box>
          <Box sx={{ marginTop: "30px" }}>
              <p className='text-[30px] font-bold'>{escapeHTML('Project Description *')}</p>
              <TextField
                helperText={showError && error.description.value}
                name="description"
                multiline
                value={values.description}
                onChange={onChange}
                placeholder={escapeHTML('Project Description *')}
                hiddenLabel
                required
              />
            </Box>

          <Box sx={{ marginTop: "30px", width: 'min(80vw, 576px)' }}>
            <p className='text-[30px] font-bold'>{escapeHTML('Token Logo *')}
              <span style={{ marginLeft: "10px" }} className="error-string">{showError && error.icon.value}</span>
            </p>
            <FileUpload square={true} onChange={onChange} name="icon" />
          </Box>

          <Box sx={{ marginTop: "30px" }}>
            <p className='text-[30px] font-bold'>Youtube Video</p>
            <TextField
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <YouTube />
                  </InputAdornment>
                ),
              }}
              name="youtube"
              value={values.youtube}
              placeholder='Enter youtube video link'
              onChange={onChange}
            />
          </Box>

          <p className="text-[40px] text-center font-bold text-montserrat mt-[50px]">
            Token Details
          </p>
    
          <Box>
            <p className='text-[30px] font-bold'>{escapeHTML('Token Name *')}</p>
            <TextField label="Token Name"
              helperText={showError && error.tokenname.value}
              name="tokenname"
              value={values.tokenname}
              onChange={onChange}
              required />
          </Box>
    
        
          <Box sx={{ marginTop: "30px" }}>
            <p className='text-[30px] font-bold'>{escapeHTML('Token Symbol *')}</p>
            <TextField label="Token Symbol"
              helperText={showError && error.tokensymbol.value}
              value={values.tokensymbol}
              name="tokensymbol"
              onChange={onChange}
              required />
          </Box>
      
          <Box sx={{ marginTop: "30px" }}>
            <p className='text-[30px] font-bold'>{escapeHTML('Token Total Supply *')}</p>
            <TextField label="Token Total Supply"
              helperText={showError && error.totalsupply.value}
              type="number"
              name="totalsupply"
              value={values.totalsupply}
              onChange={onChange}
              required />
          </Box>
        

          <p className='text-center text-[40px] font-bold mt-[50px]'>
            Token Distribution
          </p>
          <p className='text-[30px] font-bold mt-[20px]'>
            Enter the token distribution percentages for different categories.<br/>Public and Liquidity is a must.
          </p>
          <p className='text-[30px]'>
          (Distributions must add up to 100%)
          </p>
          <p className='text-[30px]'>
            {percentages.length > 0 && `Current Total: ${percentages.reduce((acc, item) => acc + parseInt(item.value || 0), 0)}%`} <br /><span className="error-string">{showError && error.distribution.value}</span>
          </p>

          <CenterBox>
            <Box sx={{ marginTop: "50px" }}>
              {percentages.map((item, i) => (
                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "min(80vw, 576px)", marginBottom: "10px" }}>
                  <TextField size="small" className='small' label="Distribution Name"
                    name={item.title}
                    value={item.title}
                    onChange={(e) => !item.disabled && onChangePercentageTitle(i, e.target.value)}
                    required
                  />
                  <TextField size="small" className='small' label="Percentage" type="number" onWheel={(e) => e.target.blur() } sx={{marginLeft:"20px", borderRadius:'5px !important'}}
                    InputLabelProps={{ shrink: true }}
                    name={`${item.title}_percentage`}
                    value={item.value}
                    onChange={(e) => onChangePercentageValue(i, e.target.value)}
                    required />
                  <IconButton onClick={() => onRemovePercentage(i)}><Close className="pink-icon" /></IconButton>
                </Box>
              ))}
              <Button className='add-more-btn' onClick={onAddDistribution}>+ Add another distribution category</Button>
            </Box>
          </CenterBox>

          
          <Box sx={{ marginTop: "30px" }}>
            <p className='text-[30px] font-bold'>{escapeHTML('Soft Cap *')}</p>
            <TextField placeholder="Soft Cap"
              helperText={showError && error.softcap.value}
              type="number"
              value={values.softcap}
              name="softcap"
              onChange={onChange}
              onWheel={(e) => e.target.blur()}
              required
            />
          </Box>
      
          <Box sx={{ marginTop: "30px" }}>
            <p className='text-[30px] font-bold'>{escapeHTML('Hard Cap *')}</p>
            <TextField placeholder="Hard Cap"
              helperText={showError && error.hardcap.value}
              type="number"
              value={values.hardcap}
              name="hardcap"
              onChange={onChange}
              onWheel={(e) => e.target.blur()}
              required
            />
          </Box>
      
          <Box sx={{ marginTop: "30px" }}>
            <p className='text-[30px] font-bold'>XRP Raised to go to Liquidity Pool %*</p>
            <TextField placeholder="XRP Raised to go Liquidity Pool %"
              helperText={showError && error.xrp_to_lp.value}
              type="number"
              value={values.xrp_to_lp}
              name="xrp_to_lp"
              onChange={onChange}
              onWheel={(e) => e.target.blur()}
              required
            />
          </Box>
        
          <p className='text-center text-[40px] font-bold mt-[50px]'>
            Launch Time
          </p>
          <div className="error-string" style={{ textAlign: "center", margin: "auto" }}>{showError && error.launchtime?.value}</div>
          <Box className="launch-time-box">
            <Box sx={{ width: "100%" }}>
              <Typography className='text-[30px] font-bold'>{escapeHTML('From *')}</Typography>
              <TextField type="datetime-local" className='small'
                helperText={showError && error.startdate.value}
                value={values.startdate}
                name="startdate"
                onChange={onChange}
                required
              />
            </Box>
            <Box sx={{ width: "100%" }}>
              <Typography className='text-[30px] font-bold'>{escapeHTML('To *')}</Typography>
              <TextField type="datetime-local" className='small'
                helperText={showError && error.enddate.value}
                name="enddate"
                value={values.enddate}
                onChange={onChange}
                required
              />
            </Box>
          </Box>


          <p className="text-[40px] text-center font-bold text-montserrat mt-[50px]">
            Contact Information
          </p>
          
            <Box sx={{ marginTop: "10px" }}>
              <p className='text-[30px] font-bold'>{escapeHTML('Website *')}</p>
              <TextField placeholder="Website Link"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Image src={Website}/>
                    </InputAdornment>
                  ),
                }}
                helperText={showError && error.website.value}
                name="website"
                value={values.website}
                onChange={onChange}
                required
              />
            </Box>
          
          
            <Box sx={{ marginTop: "30px" }}>
              <p className='text-[30px] font-bold'>Twitter</p>
              <TextField placeholder="Twitter"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Twitter />
                    </InputAdornment>
                  ),
                }}
                name="twitter"
                value={values.twitter}
                onChange={onChange}
              />
            </Box>
          
          
            <Box sx={{ marginTop: "30px" }}>
              <p className='text-[30px] font-bold'>Discord</p>
              <TextField placeholder="Discord"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Image src={Discord} />
                    </InputAdornment>
                  ),
                }}
                name="discord"
                value={values.discord}
                onChange={onChange}
              />
            </Box>
          
          
            <Box sx={{ marginTop: "30px" }}>
              <p className='text-[30px] font-bold'>Telegram</p>
              <TextField placeholder="Telegram"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Telegram />
                    </InputAdornment>
                  ),
                }}
                name="telegram"
                value={values.telegram}
                onChange={onChange}
              />
            </Box>
          
          
            <Box sx={{ marginTop: "30px" }}>
              <p className='text-[30px] font-bold'>Medium</p>
              <TextField placeholder="Medium"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Image src={Medium} />
                    </InputAdornment>
                  ),
                }}
                name="medium"
                value={values.medium}
                onChange={onChange}
              />
            </Box>
          
          
            <Box sx={{ marginTop: "30px" }}>
              <p className='text-[30px] font-bold'>White-paper</p>
              <TextField placeholder="White-paper Link"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Edit />
                    </InputAdornment>
                  ),
                }}
                name="whitepaper"
                value={values.whitepaper}
                onChange={onChange}
              />
            </Box>
          

          <p className='text-[30px] font-bold mt-[80px]'>
            Now please read through the terms and conditions linked here: <br/>Link
          </p>
          <CenterBox>
            <Checkbox {...label}
              checked={checked}
              onChange={handleChange}
              sx={{
                color: '#00000',
                '& .MuiSvgIcon-root': { fontSize: 28 }
              }}
              color="default"
              />
            <Link href="https://docs.xrplpad.io/launchpad-terms-and-conditions/for-project-founders" target="_blank" rel="noopener noreferrer">
              <p className='text-[27px] font-semibold'>
              I have read and accept the terms and conditions of using this platform.
              </p>
              
            </Link>
          </CenterBox>
          <p className='text-[27px] font-bold mt-[50px]'>
          Please click the Create Presale button to pay the 300 XRP listing fee and submit the project information. You can then share the link of the your presale page with your community.
          </p>
          <CenterBox>
            <div className=' mt-[50px]'>
            <ApplyButton text="Apply" onClick={onSubmit} />
            </div>        
          </CenterBox>
        </div>
        :
        <div>
          <div className='content-panel'>
            <p className="text-[50px] font-bold text-montserrat">
              Launch a presale
            </p>
            <p className="text-[30px] font-semibold text-montserrat">
            In order to comply with regulations, you Xaman wallet has to be KYCed.<br/>
This is done in order to protect users from potential malicious projects.<br/>
Currently KYC is only available on Xaman. Click here to download: <a href='https://xumm.app/' target='blank' className='underline'>Xaman</a><br/>Please KYC your Xaman wallet and then apply to use the presale launchpad.
            </p>
          </div>
          <div className='content-panel mt-[30px]'>
            <p className="text-[50px] font-bold text-montserrat">
              How to KYC on Xaman?
            </p>
            <p className="text-[30px] font-semibold text-montserrat">
            To be able to do KYC on Xaman, you must be subscribed to Xaman Pro (Beta).<br/><br/><span className='font-bold'>How to get Xaman Pro (Beta)? </span><br/>Download Xaman and make sure you have at least $60 equivalent of XRP available to spend  (I would suggest go slightly higher to cover any fees, reserves etc...).
            </p>
            <div>
              <p className='text-center font-semibold text-[30px] mt-[20px]'>Then go here:<br/>
                <a href='https://xrpl-labs.com/pro/' target='blank'>https://xrpl-labs.com/pro/</a>
                <br/>And click
              </p>
              <CenterBox><p className='btn-label !rounded-full'> Get Pro (Beta) now!</p></CenterBox>
              <p className='text-center font-semibold text-[30px] mt-[20px]'>Connect with your Xaman wallet and purchase the Xaman Pro (Beta)</p>            
            </div>
            <div className="mt-[50px]">
              <p className="text-[30px] text-montserrat">
               <span className='font-bold'>How do I now KYC?</span> <br/><br/>Now open your Xaman app, got to <span className='font-bold'>xApps</span> (bottom of app) and then find <br/><span className='font-bold'>Xaman Pro Beta</span><br/><br/>Click on it, it will open and then select the KYC xApp.
              </p>             
            </div>
            <p className=" text-center text-[30px] font-bold mt-[50px]">
            You can also follow these instructions to KYC your self <br/> (after subscribing to Xaman Pro)
            </p>
            <CenterBox>
              <div className='w-[80%] mt-[30px]'>
                <img src={KYCFOLLOW} alt='kyc'/>
              </div>
            </CenterBox>            
            <p className=" text-center text-[30px] font-bold mt-[50px]">
            Follow the process. Once it is finished and you are verified, you wallet is then KYCed!</p>
            <p className=" text-center text-[30px] font-bold mt-[50px]">
            You can check if you have been KYCed successfully by going to: <a href='https://xrpscan.com/' target='blank' className='underline'>xrpscan.com</a> and searching your address.<br/>If you see
            </p>
            <CenterBox>
              <img src={KYCVERIFIED} alt='kyc verified' className='w-[350px]'/>
            </CenterBox>
            <p className=" text-center text-[30px] font-bold mt-[50px]">
            This means your wallet is now KYCed and can be used on the platform to launch a presale.
            </p>
            <CenterBox> <button className='btn-label mt-[30px]' onClick={closeKycModal}>Home</button></CenterBox>
           
          </div>
        </div>
      }
      
    </Box >
  )
}


{ /*
  <Container className="apply-container">
   
    <GradientText sizesmall={24} size={60} className="explore-title">
      Apply for Launch
    </GradientText>
    {showError && Object.keys(error).length > 0 && <p className="error-string">Please check the error below.</p>}
    <GradientText sizesmall={20} size={24} top={120} topsmall={0} className="explore-subtitle">
      Project Details
    </GradientText>
    <CenterBox>
      <Box sx={{ marginTop: "50px" }}>
        <Typography className='input-label'>{escapeHTML('Project Name *')}</Typography>
        <TextField label="Project Name"
          helperText={showError && error.projectname.value}
          name="projectname"
          value={values.projectname}
          onChange={onChange}
          required
        />
      </Box>
    </CenterBox>
    <CenterBox>
      <Box sx={{ marginTop: "30px" }}>
        <Typography className='input-label'>{escapeHTML('Project Description *')}</Typography>
        <TextField label="Project Description"
          helperText={showError && error.description.value}
          name="description"
          multiline
          value={values.description}
          onChange={onChange}
          required
        />
      </Box>
    </CenterBox>
    <CenterBox>
      <Box sx={{ marginTop: "30px", width: 'min(80vw, 576px)' }}>
        <Typography className='input-label'>{escapeHTML('Icon *')}
          <span style={{ marginLeft: "10px" }} className="error-string">{showError && error.icon.value}</span>
        </Typography>
        <FileUpload square={true} onChange={onChange} name="icon" />
      </Box>
    </CenterBox>
    <CenterBox>
      <Box sx={{ marginTop: "30px" }}>
        <Typography className='input-label'>Youtube Video</Typography>
        <TextField label="Enter youtube video link"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <YouTube />
              </InputAdornment>
            ),
          }}
          name="youtube"
          value={values.youtube}
          onChange={onChange}
        />
      </Box>
    </CenterBox>
    <GradientText sizesmall={20} size={24} top={120} topsmall={0} className="explore-subtitle">
      Token Details
    </GradientText>
    <CenterBox>
      <Box sx={{ marginTop: "50px" }}>
        <Typography className='input-label'>{escapeHTML('Token Name *')}</Typography>
        <TextField label="Token Name"
          helperText={showError && error.tokenname.value}
          name="tokenname"
          value={values.tokenname}
          onChange={onChange}
          required />
      </Box>
    </CenterBox>
    <CenterBox>
      <Box sx={{ marginTop: "30px" }}>
        <Typography className='input-label'>{escapeHTML('Token Symbol *')}</Typography>
        <TextField label="Token Symbol"
          helperText={showError && error.tokensymbol.value}
          value={values.tokensymbol}
          name="tokensymbol"
          onChange={onChange}
          required />
      </Box>
    </CenterBox>
    <CenterBox>
      <Box sx={{ marginTop: "30px" }}>
        <Typography className='input-label'>{escapeHTML('Token Total Supply *')}</Typography>
        <TextField label="Token Total Supply"
          helperText={showError && error.totalsupply.value}
          type="number"
          name="totalsupply"
          value={values.totalsupply}
          onChange={onChange}
          required />
      </Box>
    </CenterBox>
    <GradientText sizesmall={20} size={24} top={120} topsmall={0} className="explore-subtitle">
      Token Distribution
    </GradientText>
    <GradientText sizesmall={14} size={16} top={0} topsmall={0} className="explore-subtitle-helper">
      Columns, must add up to 100%
    </GradientText>
    <CenterBox >
      <Box sx={{ marginTop: "50px" }}>
        <Typography className='input-label'>
          Enter Token Distribution Percentage {percentages.length > 0 && `(Total: ${percentages.reduce((acc, item) => acc + parseInt(item.value || 0), 0)}%)`} {escapeHTML('*')}<br /><span className="error-string">{showError && error.distribution.value}</span>
        </Typography>
        {percentages.map((item, i) => (
          <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "min(80vw, 576px)", marginBottom: "10px" }}>
            <TextField size="small" className='small' label="Distribution Name"
              name={item.title}
              value={item.title}
              onChange={(e) => !item.disabled && onChangePercentageTitle(i, e.target.value)}
              required
            />
            <TextField size="small" className='small' label="Percentage" type="number" onWheel={(e) => e.target.blur()}
              InputLabelProps={{ shrink: true }}
              name={`${item.title}_percentage`}
              value={item.value}
              onChange={(e) => onChangePercentageValue(i, e.target.value)}
              required />
            <IconButton onClick={() => onRemovePercentage(i)}><Close className="pink-icon" /></IconButton>
          </Box>
        ))}
        <Button className='add-more-btn' onClick={onAddDistribution}>+ Add another distribution</Button>
      </Box>
    </CenterBox>
    <CenterBox >
      <Box sx={{ marginTop: "30px" }}>
        <Typography className='input-label'>{escapeHTML('Soft Cap *')}</Typography>
        <TextField label="Soft Cap"
          helperText={showError && error.softcap.value}
          type="number"
          value={values.softcap}
          name="softcap"
          onChange={onChange}
          onWheel={(e) => e.target.blur()}
          required
        />
      </Box>
    </CenterBox>
    <CenterBox >
      <Box sx={{ marginTop: "30px" }}>
        <Typography className='input-label'>{escapeHTML('Hard Cap *')}</Typography>
        <TextField label="Hard Cap"
          helperText={showError && error.hardcap.value}
          type="number"
          value={values.hardcap}
          name="hardcap"
          onChange={onChange}
          onWheel={(e) => e.target.blur()}
          required
        />
      </Box>
    </CenterBox>
    <CenterBox >
      <Box sx={{ marginTop: "30px" }}>
        <Typography className='input-label'>XRP Raised to go to Liquidity Pool %*</Typography>
        <TextField label="XRP Raised to go Liquidity Pool %"
          helperText={showError && error.xrp_to_lp.value}
          type="number"
          value={values.xrp_to_lp}
          name="xrp_to_lp"
          onChange={onChange}
          onWheel={(e) => e.target.blur()}
          required
        />
      </Box>
    </CenterBox>
    <GradientText sizesmall={20} size={24} top={120} topsmall={0} className="explore-subtitle">
      Launch Time
    </GradientText>
    <div className="error-string" style={{ textAlign: "center", margin: "auto" }}>{showError && error.launchtime?.value}</div>
    <Box className="launch-time-box">
      <Box sx={{ width: "100%" }}>
        <Typography className='input-label'>{escapeHTML('From *')}</Typography>
        <TextField type="datetime-local" className='small'
          helperText={showError && error.startdate.value}
          value={values.startdate}
          name="startdate"
          onChange={onChange}
          required
        />
      </Box>
      <Box sx={{ width: "100%" }}>
        <Typography className='input-label'>{escapeHTML('To *')}</Typography>
        <TextField type="datetime-local" className='small'
          helperText={showError && error.enddate.value}
          name="enddate"
          value={values.enddate}
          onChange={onChange}
          required
        />
      </Box>
    </Box>
    <GradientText sizesmall={20} size={24} top={120} topsmall={0} className="explore-subtitle">
      Contact Information
    </GradientText>
    <CenterBox>
      <Box sx={{ marginTop: "50px" }}>
        <Typography className='input-label'>{escapeHTML('Website *')}</Typography>
        <TextField label="Website Link"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Image src={Website} />
              </InputAdornment>
            ),
          }}
          helperText={showError && error.website.value}
          name="website"
          value={values.website}
          onChange={onChange}
          required
        />
      </Box>
    </CenterBox>
    <CenterBox>
      <Box sx={{ marginTop: "30px" }}>
        <Typography className='input-label'>Twitter</Typography>
        <TextField label="Twitter"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Twitter />
              </InputAdornment>
            ),
          }}
          name="twitter"
          value={values.twitter}
          onChange={onChange}
        />
      </Box>
    </CenterBox>
    <CenterBox>
      <Box sx={{ marginTop: "30px" }}>
        <Typography className='input-label'>Discord</Typography>
        <TextField label="Discord"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Image src={Discord} />
              </InputAdornment>
            ),
          }}
          name="discord"
          value={values.discord}
          onChange={onChange}
        />
      </Box>
    </CenterBox>
    <CenterBox>
      <Box sx={{ marginTop: "30px" }}>
        <Typography className='input-label'>Telegram</Typography>
        <TextField label="Telegram"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Telegram />
              </InputAdornment>
            ),
          }}
          name="telegram"
          value={values.telegram}
          onChange={onChange}
        />
      </Box>
    </CenterBox>
    <CenterBox>
      <Box sx={{ marginTop: "30px" }}>
        <Typography className='input-label'>Medium</Typography>
        <TextField label="Medium"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Image src={Medium} />
              </InputAdornment>
            ),
          }}
          name="medium"
          value={values.medium}
          onChange={onChange}
        />
      </Box>
    </CenterBox>
    <CenterBox>
      <Box sx={{ marginTop: "30px" }}>
        <Typography className='input-label'>White-paper</Typography>
        <TextField label="White-paper Link"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Edit />
              </InputAdornment>
            ),
          }}
          name="whitepaper"
          value={values.whitepaper}
          onChange={onChange}
        />
      </Box>
    </CenterBox>
  </Container>
  <CenterBox className="footer-bg">
    <GradientText sizesmall={20} size={24} top={100} topsmall={30} className="explore-subtitle">
      Listing Fee = 300 XRP
    </GradientText>
    <GradientText sizesmall={20} size={24} top={50} className="explore-subtitle">
      <Checkbox {...label}
        checked={checked}
        onChange={handleChange}
        sx={{
          color: '#eeeeee'
        }} />
      <Link href="https://docs.xrplpad.io/launchpad-terms-and-conditions/for-project-founders" target="_blank" rel="noopener noreferrer" style={{ color: 'blue', fontWeight: 700, fontSize: 20 }}>
        I agree to the Terms and Conditions
      </Link>
    </GradientText>
    <ApplyButton sx={{ marginTop: "20px" }} text="Apply" onClick={onSubmit} />
  </CenterBox>
  */}