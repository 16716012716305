import React, { useState } from 'react';
import { Box, Typography, Skeleton } from '@mui/material';
import "./style.scss";
import Image from '../Image';
import DropDownIcon from "../../assets/images/dropdown.svg";
import TokenModal from '../TokenModal';
import { formatCurrency, getTokenIcon, getTokenName } from '../Global';
import { useEffect } from 'react';
import toast from 'react-hot-toast';

export default function SelectCard({ token: tokenProps, onSelect, oppositToken}) {
    const [open, setOpen] = useState(false);
    const [selectedToken, setSelectedToken] = useState();

    useEffect(() => {
        setSelectedToken(tokenProps);
    }, [tokenProps])

    const onHandleClose = (e) => {
        e.stopPropagation();

        setOpen(false);
    }

    const onSelectToken = (token) => {
        if(token?.account === oppositToken?.account && token?.currency === oppositToken?.currency) {
            toast.error("Please select different two assets")
            return
          }
        setSelectedToken(token);
        onSelect(token);
    }

    return (
        <Box className="selectcard-container" onClick={() => setOpen(true)}>
            <TokenModal open={open} onClose={onHandleClose} onSelect={onSelectToken} />
            <Box className="token-info">
                <Box className="token">
                    {
                        selectedToken ?
                            <Image className="token-icon" src={getTokenIcon(selectedToken)} alt="icon" /> :
                            <Skeleton variant="circular" width={32} height={32} />
                    }
                    <Typography className='symbol'>
                        {
                            selectedToken ? getTokenName(selectedToken) :
                                <Skeleton variant="text" width={50} />
                        }
                    </Typography>
                </Box>
                <Box component="img" src={DropDownIcon} alt="dropdown" />
            </Box>
        </Box >
    )
}