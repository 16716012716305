import React from 'react'
import "./style.scss"
import { Box } from '@mui/material'
import XummIcon from "../../assets/images/xaman.png"
import CrossmarkIcon from "../../assets/images/crossmark.png"

export default function ImageButton(props) {
    return (
        <Box {...props} className="imagebtn-container">
            <Box className="btn-wrapper">
                <img src={XummIcon} alt="" style={{ width: '120px', height: '20px' }} />
            </Box>
        </Box>
    )
}

export function ImageButtonCross(props) {
    return (
        <Box {...props} className="imagebtn-container-crossmark">
            <Box className="btn-wrapper-crossmark" style={{ display: 'flex', alignItems: 'center' }}>
                <img src={CrossmarkIcon} alt="" style={{ width: '32px', height: '32px' }} />
                <span style={{ color: 'white', fontSize:'15px' }}>CROSSMARK</span>
            </Box>
        </Box>
    );
}