export const getCSRFToken = () => {
  const token = window.sessionStorage.getItem("csrf");
  return token
}
export const getJWT = () => {
  const token = window.sessionStorage.getItem("jwt");
  return token
}

export const shortenAddress = (address) => {
  return address.substr(0,8) + "..." + address.substr(address.length-6)
}

export function escapeHTML(str) { 
  return str.replace(/[&<>"'\/]/g, function (char) { 
    switch (char) { 
      case '&': return '&amp;'; 
      case '<': return '&lt;'; 
      case '>': return '&gt;'; 
      case '"': return '&quot;'; 
      case "'": return '&#39;'; 
      case '/': return '&#x2F;'; 
      default: return char; } 
    }); 
  }

export function wait(seconds) {
  return new Promise((resolve=>setTimeout(() => {
    resolve()
  }, seconds*1000)))
}