import React, { useState } from 'react'
import "./style.scss"
import { Container, Typography } from '@mui/material'
import CenterBox from '../CenterBox'

export default function Tabs({ onSelected, className, sx }) {
    const [active, setActive] = useState(0);

    const onActive = (id) => {
        setActive(id);
        onSelected && onSelected(id);
    }

    return (
        <Container sx={sx}>
            <CenterBox className={`tab-container ${className}`} sx={{ justifyContent: "space-evenly", display: "flex" }}>
                <Typography className={`tab-item ${active === 0 ? "active" : ""}`} onClick={() => onActive(0)}>Upcoming</Typography>
                <Typography className={`tab-item ${active === 1 ? "active" : ""}`} onClick={() => onActive(1)}>On Going</Typography>
                <Typography className={`tab-item ${active === 2 ? "active" : ""}`} onClick={() => onActive(2)}>Ended</Typography>
            </CenterBox>
        </Container>
    )
}
