import React, { useContext } from 'react';
import { Box, Typography, Skeleton, Input } from '@mui/material';
import "./style.scss";
import Image from '../Image';
import { formatCurrency, getTokenIcon, getTokenName, getUSPriceFromXRP } from '../Global';
import { useState } from 'react';
import { useEffect } from 'react';
import { AppContext } from '../..';
import PlaceholderIcon from '../../assets/images/icon-placeholder.svg';
import SelectCard from '../SelectCard';

export default function InputCard({ amount, token, disabled, onChange, setToken, nomax = false }) {
    const [balance, setBalance] = useState(0);
    const { balances, loadingBalance } = useContext(AppContext);

    const [usPrice, setUSPrice] = useState(0)

    const onSelectFrom = async (token) => {
        setToken(token);
        // console.log(token, 'token');
    }
    
    useEffect(() => {
        try {
            if (token) {
                const balanceInfo = balances?.find((value) => {
                    if (value.currency === token.currency && value.account === token.account) {
                        return true;
                    }
                })
                setBalance(balanceInfo?.balance || 0);
            }
        } catch (e) { console.log(e) }

    }, [balances, token])

    const onHandleChange = (e) => {
        onChange(e.target.value);
    }

    const getUSValue = async(amount, token) => {
        console.log('token', token)
        if(token?.currency === "XRP"){
            const price = await getUSPriceFromXRP(amount)
            setUSPrice(price)
            return
        }
        console.log('amount', amount)
        if(amount===0){
            console.log('amount is 0')
            setUSPrice(0)
            return;
        }
        const price = await getUSPriceFromXRP(Number(amount) * Number(token?.price))
        console.log('price', price)
        console.log('Number(token?.price)', Number(amount) * Number(token?.price))
        setUSPrice(price)
    }

    useEffect(()=>{
        getUSValue(amount, token)
    },[amount, token])

    return (
        <Box className="inputcard-container">
            <Box className="token-info">
                <Box className="token">
                    <Box>
                        <SelectCard token={token} onSelect={onSelectFrom} />
                        <div className='flex space-x-2 w-[250px]'>
                            <Typography className='balance'>
                                Balance:&nbsp;{loadingBalance ? 'Loading...' : (balance / 1).toFixed(2) || "0.0"} { loadingBalance ? '':formatCurrency(token?.currency) } 
                            </Typography>
                            {
                                !disabled && !loadingBalance &&
                                <button className='max' onClick={() => {
                                    onHandleChange({ target: { value: balance } });
                                }}> {token?.currency ==='XRP' && <>&asymp;</>}  MAX</button>
                            }
                            
                        </div>
                    </Box>
                </Box>
                <Box className="price">
                    {!disabled ?
                        <Input
                            className='input-price' type="number" hiddenLabel defaultValue="0.0" size="medium"
                            value={amount}
                            onChange={onHandleChange}
                        /> :
                        <Typography type='number' className='input-price disabled' >
                            {amount && !isNaN(amount) ? amount : "0.0"}
                        </Typography>}

                        <Typography className={`price-label ${disabled&& 'disabled'}`} >
                            {token?.currency ==='XRP' && <>&asymp;</>} {`$ ${usPrice}` }
                        </Typography>
                </Box>
            </Box>
        </Box >
    )
}
