import React from 'react';
import "./style.scss";
import styled from 'styled-components';

const Text = styled.div`
    text-align: center;
    font-weight: 700 !important;
    background: linear-gradient(180deg, #FFF 0%, #D0DCF5 19.79%, #A8A3B2 70.21%, #1C0A41 100%, #1C0A41 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    position: relative;
    filter: drop-shadow(0px -1px #ffffff);
    line-height: normal !important;
    font-size: ${props => props.size || "inherit"}px !important;
    margin-top: ${props => props.top || "inherit"}px;
    
    @media (max-width: 800px) {
        font-size: ${props => props.sizesmall || "inherit"}px !important;
        margin-top: ${props => props.topsmall || "inherit"}px !important;
    }
`;

export default function GradientText(props) {
    const { children, sx } = props;
    return (
        <Text {...props} sx={sx}>{children}</Text>
    )
}
