import Table from 'rc-table';
import { Box, Button, Container, InputAdornment, Skeleton, TextField, Typography } from '@mui/material';
import "moment-timezone";
import "./style.scss"
import Navbar from '../../components/Navbar';
import Image from '../../components/Image';
import CenterBox from '../../components/CenterBox';
import SearchIcon from "../../assets/images/search.svg";
import { Link, useNavigate } from 'react-router-dom';
import { useContext, useEffect, useRef, useState, useCallback } from 'react';
import toast from 'react-hot-toast';
import { formatNumber, getAMMInfo, getAccountBalance, getTokenIcon, getTokenPrice, tokenMetaToAmount, tokenMetaToCurrency } from '../../components/Global';
import { AppContext } from '../..';
import axios from 'axios'

const AllLiquidity = () => {
    const { liquidity, setLiquidity, balances, manageFromToken, manageToToken } = useContext(AppContext);
    const navigate = useNavigate();
    const [filterList, setFilterList] = useState(liquidity);

    const columns = [
        {
            key: 'token',
            render: (item) =>
                <Box sx={{ display: "flex", alignItems: "center", paddingLeft: "20px" }}>
                    <Box className="pair-token">
                       
                            <Image className="token-icon" src={getTokenIcon(item.token)} alt="from" /> :
                            {/* <Skeleton variant="circular" width={32} height={32} /> */}
                       
                     
                            <Image className="token-icon" src={getTokenIcon(item.token2)} alt="to" /> :
                            {/* <Skeleton variant="circular" width={32} height={32} /> */}
                        
                    </Box>
                    <Typography className='pair-label'>
                        {tokenMetaToCurrency(item.pool.amount)}/{tokenMetaToCurrency(item.pool.amount2)}
                    </Typography>
                </Box>
        },
        {
            dataIndex: 'pool',
            key: 'fee',
            render: (item) =>
                <Box sx={{ alignItems: "start",}}>
                    <Typography className='pair-label'>
                        <>{item ? `${formatNumberRemoveToZero(item?.trading_fee / 1000)}%` : "-"}</>
                    </Typography>
                    <Typography className='sub-label'>
                        Trading Fee
                    </Typography>
                </Box>      
                
        },
        {
            key: 'liquidity',
            render: (item) =>
                <Box sx={{ alignItems: "start",}}>
                    <Typography className='pair-label'>
                        <> &asymp; {item?.pool ? ` ${item.pool.amount.value?formatNumber( parseFloat(item.pool.amount.value)*2):formatNumber(parseFloat(item.pool.amount)/1000000*2) }` : "-"}</>
                    </Typography>
                    <Typography className='sub-label'>
                        Total Liquidity
                    </Typography>
                </Box>
        },
        {
            key: 'share',
            render: (item) => {
                const lp_my_amount = balances?.find(val =>
                    val.currency === item?.pool.lp_token.currency
                );
                return <Box sx={{ alignItems: "start",}}>
                            <Typography className='pair-label'>
                                <>{lp_my_amount?.balance ? `${formatNumber(parseFloat(lp_my_amount.balance)/parseFloat(item.pool.lp_token.value)*100)} %` : "-"}</>
                            </Typography>
                            <Typography className='sub-label'>
                                Your Liquidity
                            </Typography>
                        </Box>
            }
        },

        {
            key: 'apy',
            render: (item) => 
                <Box sx={{ alignItems: "start",}}>
                    <Typography className='pair-label'>
                    <> {item.apy==="-"?"-":`${formatNumberRemoveToZero(item.apy)} %`}</>
                    </Typography>
                    <Typography className='sub-label'>
                        APY
                    </Typography>
                </Box>    
               
        },
        {
            render: (item) =>
                <button className="btn-liquidity" onClick={() => manageLiquidity(item.token, item.token2)}>Deposit</button>
        }
        ,
        {
            render: (item) =>
                <button className="btn-liquidity" onClick={() => manageLiquidity(item.token, item.token2)}>Withdraw</button>
        },
    ];

    const manageLiquidity = (token, token2) => {
        manageFromToken.current = token;
        manageToToken.current = token2;
        navigate("/liquidity/add");
    }

    const getAllLiquidity = useCallback(async () => {
        try {
            const res = await fetch(`${process.env.REACT_APP_SERVER_URL}/pools`);

            const data = await res.json();

            data.result.map(async (pool) => {
                let apy = "-"
                const token = JSON.parse(pool.token);
                const token2 = JSON.parse(pool.token2);
                const amm_info = await getAMMInfo(token, token2);
                if(amm_info.result ===  undefined) return;
                const trading_fee = amm_info.result.result.amm.trading_fee
                if(typeof amm_info.result.result.amm.amount === 'string'){
                    const totalValue = parseFloat(amm_info.result.result.amm.amount) * 2
                    const { data } = await axios.get(`https://data.xrplf.org/v1/iou/volume_data/XRP?interval=1d&exclude_amm=false&only_amm=true&descending=true&limit=1`)
                    if(data.length > 0){
                        apy = data[0].volume *  365 * parseFloat(trading_fee) / totalValue * 100
                    }
                }else{
                    const totalValue = parseFloat(amm_info.result.result.amm.amount.value) * 2
                    const { data } = await axios.get(`https://data.xrplf.org/v1/iou/volume_data/${amm_info.result.result.amm.amount.issuer}_${amm_info.result.result.amm.amount.currency}?interval=1d&exclude_amm=false&only_amm=true&descending=true&limit=1`)
                    if(data.length > 0){
                        apy = data[0].volume *  365  * parseFloat(trading_fee) / totalValue * 100
                    }
                }
                
                if (amm_info.result) {
                    setLiquidity(prev => [
                        ...prev,
                        {
                            apy,
                            pool: amm_info.result.result.amm,
                            token: token,
                            token2: token2,
                        }
                    ]);
                    setFilterList(prev => [
                        ...prev,
                        {
                            apy,
                            pool: amm_info.result.result.amm,
                            token: token,
                            token2: token2,
                        }
                    ]);
                }
            });

        } catch (e) {
            console.log(e);
            throw e;
        }
    }, []);

    const onSearchLiquidity = (e) => {
        const filteredLiquidity = liquidity.filter((item) => {
            const token = tokenMetaToCurrency(item.token).toLowerCase();
            const token2 = tokenMetaToCurrency(item.token2).toLowerCase();

            return token.includes(e.target.value.toLowerCase()) || token2.includes(e.target.value.toLowerCase());
        });

        setFilterList(filteredLiquidity);
    }

    useEffect(() => {
        if (liquidity.length > 0) return;

        toast.promise(
            getAllLiquidity(),
            {
                loading: 'Loading All liquidity pools...',
                success: "Successfully loaded.",
                error: "Can't read the list of pools.",
            }
        );
    }, []);

    const filteredList = filterList.filter(item => {
        const lp_my_amount = balances?.find(val => val.currency === item?.pool.lp_token.currency);
        const shareValue = lp_my_amount?.balance;

        // Only include items where the share value is a number greater than 0
        const parsedShareValue = parseFloat(shareValue);
        return !isNaN(parsedShareValue) && parsedShareValue > 0;
    });

    return (
        <>
            <Box className="all-liquidity-box">
                <Navbar />
                <Container className="all-liquidity-container">
                    <Box className="table-container">
                        <Box className='title-box'>
                            <Typography className='label'>My Pools</Typography>
                            <Box className="action-box">
                                <Link to="/liquidity/add" >
                                    <button className='btn-liquidity' >
                                        Create New
                                    </button>
                                </Link>
                            </Box>
                        </Box>
                        <Box className="scroll-box">
                            {filteredList.length > 0 ? (
                        <Table className='tbl-liquidity' columns={columns} data={filteredList}  />
                            ) : (<>{
                                balances.length === 0 ?
                                <Typography className='no-table-note'  >{"Please Connect Wallet"} <br/> <p className='sub-content'>(Top right)</p> </Typography>
                                :
                                <Typography className='no-table-note'  >{"You are not in any pools :("} <br/> <p className='sub-content'>If you are please refresh</p> </Typography>

                            }
                            </>
                            )}
                        </Box>
                    </Box>
                </Container>
            </Box>
            <Box className="flex justify-center liq-desc px-[142px] pt-[40px] pb-[97px]">
                <div className='flex flex-col space-y-4'>
                    <p className='title-explain'>Liquidity Pools on XRP Explained</p>
                    <div className='flex flex-col space-y-2 '>
                        <div className='flex justify-around'>
                            <div className=' bg-white rounded-[36px] w-[370px] px-10 py-8'>
                                <p className=' font-poppin text-[24px] font-semibold mb-3'>Enables Easier Trading</p>
                                <p className=' font-inter text-[16px]'>The AMM Amendment to XRP enables the creation of liquidity pools, just like on ETH, SOL, BSC. This enables easier and more instant trading than just the order-book model.</p>
                            </div>
                            <div className=' bg-white rounded-[36px] w-[370px] px-10 py-8'>
                                <p className=' font-poppin text-[24px] font-semibold mb-3 text-center'>Passive Income Opportunity</p>
                                <p className=' font-inter text-[16px]'>By providing liquidity to liquidity pools users can receive passive income which comes from the trading fees. When users withdraw liquidity they can collect in on those fees.</p>
                            </div>
                        </div>
                        <div className='flex justify-around'>
                            <div className=' bg-white w-[370px] h-[370px] px-10 py-8'></div>
                            <div className=' bg-white w-[370px] h-[370px] px-10 py-8'></div>
                        </div>
                    </div>
                    
                    <p className='title-explain'>Want to learn more? Click here for a short video!</p>
                </div>
            </Box>
        </>
    );
};

const formatNumberRemoveToZero = (numString, digits) => {
    numString = numString.toString();
    let digit = 0, i;
    if(numString.indexOf('.') !== -1) {
        for(i = numString.indexOf('.') + 1; i < numString.length; i++){
            if(numString[i] === '0' && digit === 0) continue;
            digit++;
            if(digit === 4) break;
        }
    } else {
        return numString;
    }
    numString = numString.slice(0, i);
    if(numString[numString.length - 1] === '0') numString = numString.slice(0, numString.length - 1)
    if(numString[numString.length - 1] === '0') numString = numString.slice(0, numString.length - 1)
    const parts = numString.split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join('.') + (digits > 0 ? '.' + parts[1] : '');
  }

export default AllLiquidity;
