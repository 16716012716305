import React from 'react'
import "./style.scss"

export default function Button(props) {
    const { text, onClick } = props;
    return (
        <button className='btn-label' onClick={onClick}>{text}</button>

    )
}
