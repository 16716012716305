import { Avatar, Box, Button, Container, ListItem, ListItemAvatar, ListItemText, Tooltip, Typography } from '@mui/material';
import "moment-timezone";
import "./style.scss"
import Navbar from '../../components/Navbar';
import PlaceholderIcon from '../../assets/images/icon-placeholder.svg';
import { formatAddress, formatCurrency, showResponseResult } from '../../components/Global';
import { AppContext } from '../..';
import { useContext, useState } from 'react';
import sdk from '@crossmarkio/sdk';
import toast from 'react-hot-toast';
import LoadingModal from '../../components/LoadingModal';
import { getCSRFToken, getJWT } from '../../utils';

const Faucet = () => {
    const { faucets, address, balances } = useContext(AppContext);
    const defaultSteps = [
        {
            label: 'Create a trustline',
            description: `Please add a trustline for this faucet. This will be helpful to receive faucet.`,
        },
        {
            label: 'Sending faucet to',
            subtitle: address,
            description: `We will send faucet to your account. Please don't refresh the page.`,
        },
    ];

    const [currentStep, setCurrentStep] = useState(0);
    const [openLoading, setOpenLoading] = useState(false);
    const [steps, setSteps] = useState(defaultSteps);

    const closeLoadingModal = () => {
        setOpenLoading(false);
        setSteps(defaultSteps);
        setCurrentStep(0);
    }

    const onFaucet = async (item) => {
        if (!item) {
            toast.error("No faucet");
            return;
        }
        if (!address) {
            toast.error("Please connect wallet");
            return;
        }

        setOpenLoading(true);

        let existTrustline = false;
        balances.forEach((balance) => {
            if (balance.currency === item.currency && balance.account === item.account) {
                existTrustline = true;
            }
        });

        if (!existTrustline) {
            const { response } = await sdk.methods.signAndSubmitAndWait({
                TransactionType: 'TrustSet',
                Account: address,
                LimitAmount: {
                    currency: item.currency,
                    issuer: item.account,
                    value: "1000000"
                },
            });

            const isSuccess = showResponseResult(response);

            if (!isSuccess) {
                closeLoadingModal();
                return;
            }
        }

        setCurrentStep(1);
        try {
            await fetch(`${process.env.REACT_APP_SERVER_URL}/faucet`, {
                method: "post",
                headers: {
                    "Content-Type": "application/json",
                    
                    "authorization": `Bearer ${getJWT()}`
                },
                body: JSON.stringify({
                    currency: item.currency,
                    account: item.account,
                    address: address
                })
            });

            toast.success(`Successfully received 10 ${formatCurrency(item.currency)}.`);
        } catch (e) {
            console.log(e);
            toast.error(e.message || e);
        } finally {
            closeLoadingModal();
        }
    }

    return (
        <Box className="faucet-box">
            <Navbar />
            <LoadingModal open={openLoading} steps={steps} currentStep={currentStep} />
            <Container className="faucet-container">
                <Box className='title-box'>
                    <Typography className='label'>Faucet</Typography>
                </Box>
                <Box className="scroll-box">
                    {faucets.length === 0 && (
                        <Typography className='no-data'>No data</Typography>
                    )}
                    {faucets.map((item, index) => {
                        if (!item.account) return null;

                        return (
                            <ListItem
                                key={index}
                                className='token-item'
                            >
                                <ListItemAvatar>
                                    <Avatar className='icon-box'>
                                        <Box
                                            className='token-icon'
                                            component="img"
                                            src={PlaceholderIcon}
                                            alt="icon"
                                        />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                    primary={formatCurrency(item.currency)}
                                    secondary={
                                        <Tooltip title={item.account}>
                                            <Typography className='token-issuer'>
                                                {formatAddress(item.account)}
                                            </Typography>
                                        </Tooltip>}
                                />
                                <Button className='btn-faucet' onClick={() => onFaucet(item)}>Get</Button>
                            </ListItem>
                        )
                    })}
                </Box>
            </Container>
        </Box>
    );
};

export default Faucet;
