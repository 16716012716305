import Table from 'rc-table';
import { Box, Button, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useContext } from 'react';
import { useState } from 'react';
import moment from 'moment';
import "moment-timezone";
import { toast } from 'react-hot-toast';
import { Link as LinkIcon } from '@mui/icons-material';
import "./style.scss"
import { AppContext } from '../..';
import { xumm } from '../..';
import LoadingModal from '../LoadingModal';

import { checkProjectSuccess } from '../Global';
import { getCSRFToken, getJWT } from '../../utils';

const Transactions = ({ projectId }) => {
    const columns = [
        // {
        //     title: 'TxID',
        //     dataIndex: 'transaction_id',
        //     key: 'txid',
        //     render: (r) => {
        //         const text = r.substring(0, 6) + '...' + r.substring(r.length - 6);
        //         return (
        //             <div className="tx">
        //                 {/* {text} <a className="link-icon" target="_blank" href={`https://testnet.xrpl.org/transactions/${r}`}><LinkIcon /></a> */}
        //                 {text} <a className="link-icon" target="_blank" href={`https://devnet.xrpl.org/transactions/${r}`}><LinkIcon /></a>
        //             </div>
        //         )
        //     },
        // },
        {
            // title: <div className='text-black font-bold test-[20px]'>Amount</div>,
            dataIndex: 'xrp_amount',
            key: 'amount',
            render: (r) => <div className='text-black font-bold text-[40px] text-center'>{r / 1000000} XRP</div>,
        },
        // {
        //     title: 'Tx Verified',
        //     dataIndex: 'is_checked',
        //     key: 'is_checked',
        //     render: (r) => r == "1" ? <div className='green'>Verified</div> : "Not Verified",
        // },
        // {
        //     title: 'Refunded',
        //     dataIndex: 'is_refunded',
        //     key: 'is_refunded',
        //     render: (r) => r == "1" ? <div className='green'>Refunded</div> : "-",
        // },
        // {
        //     title: 'Completed',
        //     dataIndex: 'is_listing',
        //     key: 'is_listing',
        //     render: (r) => r == "1" ? <div className='green'>Completed</div> : "-",
        // },
        // {
        //     title: 'Type',
        //     dataIndex: 'action',
        //     key: 'action',
        //     render: (r) => <div className='green'>{r == "1" ? "Token Purchase" : " Project Application"}</div>,
        // },
        // {
        //     title: 'Updated',
        //     dataIndex: 'updated_at',
        //     key: 'updated_at',
        //     render: (r) => moment(r).tz("Africa/Abidjan", true).format("YYYY-MM-DD HH:mm:ss"),
        // },
        {
            title: '',
            dataIndex: '',
            key: 'operations',
            render: (r, record) =>
                <div className='text-center'>
                    {
                        record.action == "1" && projectData?.status === -1 && !isSuccess ?
                            <button className="small" variant="contained" onClick={() =>  { record?.is_refunded === "0" ?onRefund(record?.transaction_id):console.log('refunded already')} }>
                                { record?.is_refunded == "0" ?<p className="text-[25px] text-white bg-black font-semibold px-3 rounded-xl">Refund</p>:<p className="text-[25px] text-white bg-[#DA0D0D] font-semibold px-3 rounded-xl">Refunded</p>}
                            </button>
                            : record.action == "1" && isSuccess ? <button className="small"  onClick={() => record?.is_listing == "0"?onClaim(record?.transaction_id):console.log('claimed already') }>
                           {record?.is_listing == "0"?<p className="text-[25px] text-white bg-black font-semibold px-3 rounded-xl">Claim</p>:<p className="text-[25px] text-white bg-[#1DC719] font-semibold px-3 rounded-xl">Claimed</p>} 
                        </button> : <></>
                    }
                </div>
        },
    ];

    const defaultSteps = [
        {
            label: 'Confirming Escrow',
            subtitle: process.env.REACT_APP_XRP_CONTRACT_ADDRESS,
            description: `We are sorry the project failed. However, you will be refunded in full.`,
            qr: false,
        },
        {
            label: 'Confirming Refund',
            description: `Your XRP is being sent to you now. Thank you for using XRPL PAD!`,
            tx: true,
        },
    ];

    const claimSteps = [
        {
            label: 'Verifying',
            description: `We are verifying your claim...`,
            qr: false,
        },
        {
            label: 'Confirming Transaction',
            description: `The tokens have been sent to you. Thank you for using XRPL PAD. You can now trade tokens by clicking Swap. `,
        },
    ];

    const { address } = useContext(AppContext);
    const [currentStep, setCurrentStep] = useState(0);
    const [openLoading, setOpenLoading] = useState(false);
    const [isSuccess, setSuccess] = useState(false);
    const [data, setData] = useState([]);
    const [projectData, setProjectData] = useState(null);
    const [steps, setSteps] = useState([]);

    const closeLoadingModal = () => {
        setOpenLoading(false);
        setCurrentStep(0);
    }

    const onRefund = async (transaction_id) => {
        setCurrentStep(0);
        setOpenLoading(true);
        try {
            const res_refund = await fetch(`${process.env.REACT_APP_SERVER_URL}/valid_refund`, {
                method: "post",
                headers: {
                    "Content-Type": "application/json",
                    
                    "authorization": `Bearer ${getJWT()}`
                },
                body: JSON.stringify({
                    project_id: projectId,
                    transaction_id: transaction_id
                })
            });
    
            const validRefund = await res_refund.json();
            if (!validRefund.result) {
                closeLoadingModal();
                throw new Error(validRefund.error);
            }
            setCurrentStep(1);
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/confirm_refund`, {
                method: "post",
                headers: {
                    "Content-Type": "application/json",
                    
                    "authorization": `Bearer ${getJWT()}`
                },
                body: JSON.stringify({
                    transaction_id: transaction_id,
                    project_id: projectId,
                })
            });

            const data = await response.json();
            if (data?.error) {
                closeLoadingModal();
                toast.error(data?.error);
                return;
            } else {
                closeLoadingModal();
                setData(prev => prev.map(d => {
                    if (d.transaction_id === transaction_id) {
                        return {
                            ...d,
                            is_refunded: 1
                        }
                    }
                    return d
                }));
                toast.success("Successfully refunded");
                return;
            }

        } catch (err) {
            console.log(err);
            closeLoadingModal();
            toast.error(err.message);
        }
    }

    const onClaim = async (transaction_id) => {
        setCurrentStep(0);
        setOpenLoading(true);
        try {
            const res_claim = await fetch(`${process.env.REACT_APP_SERVER_URL}/valid_claim`, {
                method: "post",
                headers: {
                    "Content-Type": "application/json",
                    
                    "authorization": `Bearer ${getJWT()}`
                },
                body: JSON.stringify({
                    project_id: projectId,
                    transaction_id: transaction_id
                })
            });
    
            const validClaim = await res_claim.json();
            if (!validClaim.result) {
                closeLoadingModal();
                throw new Error(validClaim.error);
            }
            setCurrentStep(1);
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/confirm_claim`, {
                method: "post",
                headers: {
                    "Content-Type": "application/json",
                    
                    "authorization": `Bearer ${getJWT()}`
                },
                body: JSON.stringify({
                    transaction_id: transaction_id,
                    project_id: projectId,
                })
            });

            const data = await response.json();
            if (data?.error) {
                closeLoadingModal();
                toast.error(data?.error);
                return;
            } else {
                closeLoadingModal();
                setData(prev => prev.map(d => {
                    if (d.transaction_id === transaction_id) {
                        return {
                            ...d,
                            is_listing: 1
                        }
                    }
                    return d
                }));
                toast.success("Successfully claimed");
                return;
            }

        } catch (err) {
            console.log(err);
            closeLoadingModal();
            toast.error(err.message);
        }
    }

    const getContributions = async () => {
        try {
            const res_contrib = await fetch(`${process.env.REACT_APP_SERVER_URL}/contribution`, {
                method: "post",
                headers: {
                    "Content-Type": "application/json",
                    
                    "authorization": `Bearer ${getJWT()}`
                },
                body: JSON.stringify({
                    project_id: projectId,
                    address: address
                })
            });

            const contributions = await res_contrib.json();
            setData(contributions.result);

        } catch (err) {
            toast.error(err.message);
        }
    }

    const getProject = async () => {
        const res_project = await fetch(`${process.env.REACT_APP_SERVER_URL}/projects/${projectId}`);
        const project = await res_project.json();
        const success = checkProjectSuccess(project.result[0]?.status, project.result[0].soft_cap, project.result[0].token_raised);
        setSuccess(success);
        if(success) setSteps(claimSteps);
        else setSteps(defaultSteps);
        setProjectData(project.result[0]);
    }

    useEffect(() => {
        if (address && projectId) {
            getContributions();
            getProject();
        }
    }, [projectId, address]);

    return (
        <Box className="table-container">
            <LoadingModal open={openLoading} steps={steps} currentStep={currentStep} />
            {data.length > 0 ?
                <Table columns={columns} data={data} /> :
                <Typography className='empty-text !my-5'>No Contribution</Typography>}

        </Box>
    );
};

export default Transactions;
