import React, { useContext, useEffect, useState } from 'react'
import Navbar from '../../components/Navbar';
import { Box, Container, Pagination, Typography, Accordion, AccordionDetails, AccordionSummary} from '@mui/material';
import GradientText from '../../components/GradientText';
import Tabs from '../../components/Tabs';
import ColorBox from '../../components/ColorBox';
import Footer from '../../components/Footer';
import CenterBox from '../../components/CenterBox';
import Button from '../../components/Button';
import Link from "../../components/Link";
import "./style.scss";
import { AppContext } from '../..';
import LaunchpadPng from '../../assets/images/launchpad.png'
import HowWorkPng from '../../assets/images/howwork.png'
import LinePng from '../../assets/images/line.png'
import { FaArrowRightLong , FaArrowDownLong } from "react-icons/fa6";


const faq = [{
  title: "What is the  XRPL PAD Launchpad?",
  content: "XRPL PAD Launchpad is a presale platform for project founders to launch, mint and generate liquidity for their new XRP based project."
}, {
  title: "How can we participate in a Presale?",
  content: "Click on the project you are interested in, make sure to read through the description and then enter the amount of xrp you wish to invest (min is 10 XRP). When the presale is over you can claim the tokens and receive on your wallet."
}, {
  title: "What if the presale fails?",
  content: "If the presale fails then the XRP is fully refundable to the buyer."
}, {
  title: "How to list my own project?",
  content: "Click Apply. Fill out the project details and then pay the listing fee of 300 XRP. If presale is successful, XRPL PAD only takes 5% of the total raised."
}, {
  title: "How to get in contact?",
  content: "Below is the link to our Discord server. Join and ask any questions you like!"
}]

export default function Projects() {
  const { projectsData } = useContext(AppContext);
  const [projects, setProjects] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState(0);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const projectsPerPage = 5;

  const onTabSelected = (selectedId) => {
    if (selectedId === 0) {
      setSelectedStatus(0);
    } else if (selectedId === 1) {
      setSelectedStatus(1);
    } else if (selectedId === 2) {
      setSelectedStatus(-1);
    }
  }

  const getPageProjects = (page) => {
    const startIndex = (page - 1) * projectsPerPage;
    const endIndex = startIndex + projectsPerPage;
    const currentTabProjects = projectsData.filter((project) => project.status === selectedStatus);
    setTotalPages(Math.ceil(currentTabProjects.length / projectsPerPage));
    const projectsToShow = currentTabProjects.slice(startIndex, endIndex);
    setProjects(projectsToShow);
  }

  useEffect(() => {
    if (projectsData && projectsData.length > 0) {
      getPageProjects(page);
    }
  }, [projectsData, selectedStatus]);

  const handleChange = (e, value) => {
    setPage(value);
    getPageProjects(value);
  }

  return (
    <Box className="projects-box">
      <Container className="projects-container">
        <Navbar />
        {/* <GradientText sizesmall={24} size={60} className="presale-launchpad">
          Presale Launchpad 
        </GradientText>
        <CenterBox>
          <div className='presale-description'>
            Get in early on new upcoming projects on XRP. <br />Are you looking to launch your XRP Project?<br /> Click Apply below
          </div>
        </CenterBox>
        <CenterBox>
          <Link href="/apply"><Button className="apply-btn" text="Apply" /></Link>
        </CenterBox> */}
        <div className='content-panel'>
          <div className='title-box'>
            <p className='title'>Launchpad</p>
            <p className='sub-title'>Participate in upcoming XRP based presales</p>
          </div>
          <div className='body-box intro grid grid-cols-5'>
            <div className='col-span-3'>
              <div className='grid grid-cols-2 h-[700px]'>
                <div className='flex flex-col place-content-between '>
                  <div className='desc-item'> 
                    <p className='title'>
                    Participate in Presales
                    </p>
                    <p className='desc'>
                    You are able to participate in upcoming XRP based presales. Browse the projects or even launch your own!
                    </p>
                  </div>
                  <div className='desc-item'> 
                    <p className='title'>
                    Refundable
                    </p>
                    <p className='desc'>
                    In the event the presale fails, meaning softcap has not been met, you are able to receive a full refund.
                    </p>
                  </div>
                  <div className='desc-item'> 
                    <p className='title'>
                    Real Presale Model
                    </p>
                    <p className='desc'>
                    Whilst similar “presale” websites on XRP exists, most follow dont follow a fundraising route, but rather just set a sell order on their DEX.<br/><br/>However, this platform, follows the traditional presale approach of raising funds, soft caps, hard caps etc... which is more popular in the crypto community.
                    </p>
                  </div>
                </div>
                <div className='flex flex-col  place-content-between'>
                  <div className='desc-item'> 
                    <p className='title'>
                    Protected Liquidity Pools
                    </p>
                    <p className='desc'>
                    The raised XRP that is delegated for liquidity, those LP tokens stay on this platform indefinitely. Therefore the risk of someone pulling majority of the liquidity is very low. <br/> <br/>Ofcourse always do your own research into the project.
                    </p>
                  </div>
                  <div className='desc-item'> 
                    <p className='title'>
                    Easy Launch
                    </p>
                    <p className='desc'>
                    If you are looking to launch your own project, its really easy! Click the link below to launch your presale.
                    </p>
                  </div>
                  <div className='desc-item'> 
                    <p className='title'>
                    Presale Founders KYCed
                    </p>
                    <p className='desc'>
                    In order for someone to launch a presale they must be KYCed. This is done to help protect the users of the platform.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-span-2'>
              <div>
                <img src={LaunchpadPng} width={500} height={500}/>
              </div>
            </div>
          </div>
        </div>
        <div className='content-panel mt-[100px]'>
          <div className='title-box'>
            <p className='title'>Projects</p>
          </div>
          <Tabs className=" mt-[40px]" onSelected={onTabSelected} />
          <Box>
            {projects && projects.length > 0 ? projects.map((project) =>
              <ColorBox
                key={project.id}
                tokenData={project}
              />) :
              <Typography className='empty-text'>No Projects Listed <br/>Currently</Typography>
            }
          </Box>
                  
          <Pagination sx={{ marginTop: "80px", display: "flex", justifyContent: "center" }}
            count={totalPages}
            page={page}
            onChange={handleChange}
            variant="outlined" />
        </div> 

         <div className='content-panel mt-[100px]'>
          <div className='title-box'>
            <p className='title'>How it works?</p>
          </div>
         
          <div className='flex space-x-10 items-center  ml-5'>
            <div className='desc-item'> 
              <p className='title'>
              See a project you like? <br/>Click on it!
              </p>
            </div>  
            <FaArrowRightLong size={40}/>
            <div className='desc-item'> 
              <p className='title'>
              Read through the project
              </p>
              <p className='desc'>
              Check their website, their socials and community. Do your own research on the project you wish to contribute to.
              </p>
            </div>
            <FaArrowRightLong size={40}/>
            <div className='desc-item'> 
              <p className='title'>
              Participate in Presale
              </p>
              <p className='desc'>
              Once a presale has began, feel free to participate in it. In exchange you will receive that projects token when presale is over. 
              </p>
            </div>          
          </div>
          <div className='w-full flex justify-around pt-4'>
            <div className='pt-20'> 
              <img src={HowWorkPng} width={600} height={600} alt='how it works' />
            </div>
            <div className='flex flex-col space-y-4 items-center'>
              <FaArrowDownLong size={40}/>
              <div className='desc-item'> 
                <p className='title'>
                 Presale Ends
                </p>             
              </div>
              <img src={LinePng} width={662} height={36} alt='line'/>
              <div className='flex w-[1000px] justify-between'>
                <div className='flex flex-col space-y-4 items-center'>
                  <div className='desc-item'> 
                    <p className='title'>
                    Presale Fail
                    </p>
                    <p className='desc'>
                    The softcap has not been met
                    </p>
                  </div>
                  <FaArrowDownLong size={40}/>
                  <div className='desc-item'> 
                    <p className='title'>
                    Claim Refund
                    </p>
                    <p className='desc'>
                    Go to the projects token page and click refund. Your XRP will instantly be received on your wallet
                    </p>
                  </div>
                </div>
                <div className='flex flex-col space-y-4 items-center '>
                  <div className='desc-item'> 
                    <p className='title'>
                    Presale Success
                    </p>
                    <p className='desc'>
                    The softcap has been met
                    </p>
                  </div>
                  <FaArrowDownLong size={40}/>
                  <div className='desc-item'> 
                    <p className='title'>
                    Claim Tokens
                    </p>
                    <p className='desc'>
                    Go to the projects token page and click claim. The tokens would instantly be received on your wallet
                    </p>
                  </div>
                  <FaArrowDownLong size={40}/>
                  <div className='desc-item'> 
                    <p className='title'>
                    Trade
                    </p>
                    <p className='desc'>
                    Click on Trade button on the project page to then trade your new token.
                    </p>
                  </div>
                </div>

              </div>
            </div>
          </div>
         </div> 
          
          
         <div className='content-panel mt-[100px] flex items-center  justify-between'>
          <div className='title-box'>
            <p className='title'>Want to Launch own project?</p>
            <p className='sub-title'>For more information on launching projects and how the process works, please click here </p>
          </div>
          <a href='/apply' className='btn-label'>Click Here <br/>To Launch your Presale</a>
         </div> 



        {/* <GradientText sizesmall={24} size={60} className="subtitle">
          Frequently Asked Questions
        </GradientText>

        {faq.map(data =>
          <Accordion key={data.title} className='accordion-container'>
            <AccordionSummary>
              {data.title}
            </AccordionSummary>
            <AccordionDetails>
              {data.content}
            </AccordionDetails>
          </Accordion>
        )} */}

      </Container>
    </Box >
  )
}
