import React, { useCallback, useEffect, useRef, useState } from 'react';
import { WagmiProvider } from 'wagmi';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { config } from './wagmi/config';
import * as ReactDOM from "react-dom/client";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import reportWebVitals from './reportWebVitals';
import './index.css';
import "./star.scss";
import "./global.scss";
import Home from "./pages/home";
import Projects from "./pages/projects";
import Apply from "./pages/apply";
import Detail from "./pages/detail";
import Swap from "./pages/swap";
import Liquidity from "./pages/liquidity";
import AllLiquidity from "./pages/allLiquidity";
import Bridge from "./pages/bridge";
import Faucet from "./pages/faucet";
import { Xumm } from 'xumm';
import { Toaster, toast } from 'react-hot-toast';
import sdk from '@crossmarkio/sdk';
import AddLiquidity from './pages/addLiquidity';
// import { EVENTS } from '@crossmarkio/sdk/dist/src/services';
import { getAccountBalance } from './components/Global';
import Leaderboard from './pages/Leaderboard';
import axios from 'axios';
import Footer from './components/Footer';
import { convertHextoString } from './pages/Leaderboard';
import XGOPng from './assets/images/XGO.png'
import XRDOGEPng from './assets/images/XRDOGE.jpeg'
import XRPHPng from './assets/images/XRPH.png'


export const AppContext = React.createContext();
export const xumm = new Xumm(process.env.REACT_APP_XRP_APP_ID, process.env.REACT_APP_XRP_API_KEY);

const queryClient = new QueryClient()

function AppProvider({ children }) {
  const getAddress = () => {
    const _crossmark = localStorage.getItem("crossmark");
    if (_crossmark) {
      const json = JSON.parse(_crossmark);
      return json.address;
    }

    const data = localStorage.getItem("XummPkceJwt");
    if (data) {
      const json = JSON.parse(data);
      return json.me.account;
    }

    return '';
  }

  const [address, setAddress] = useState(getAddress);
  const [projectsData, setProjectsData] = useState([]);
  const [tokens, setTokens] = useState([]);
  const [liquidity, setLiquidity] = useState([]);
  const [balances, setBalances] = useState([]);
  const [faucets, setFaucets] = useState([]);
  const [loadingBalance, setLoadingBalance] = useState(false)
  const manageFromToken = useRef();
  const manageToToken = useRef();

  const getProjects = useCallback(async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/projects`);
      const data = await response.json();
      setProjectsData(data.result);
    } catch (error) {
      console.log(error);
      toast.error(error.message);
    }
  }, []);

  const getTokens = useCallback(async () => {
    try {
      const xrpToken = {
        currency: "XRP",
        issuer: null,
        meta: {
          token: {
            name: "XRP",
            icon: "https://static-00.iconduck.com/assets.00/xrp-cryptocurrency-icon-2048x2048-2a0bicgj.png"
          }
        },
        metrics: {
          price: 0.0
        }
      }

      const response = await fetch(`https://s1.xrplmeta.org/tokens?limit=50`);
      const data = await response.json();
      console.log(data, 'data');

      const res = await fetch(`${process.env.REACT_APP_SERVER_URL}/price`);
      const price = await res.json();

      xrpToken.metrics.price = price.result;

      setTokens([xrpToken, ...data.tokens]);
    } catch (error) {
      console.log(error);
      toast.error("Failed to retrieve token list.");
    }
  }, []);

  const handleSuccess = async () => {
    xumm.user.account.then(async (addr) => {
      if (addr) {
        toast.success('Connected');
        let tmp = []
        if (balances.length === 0) {
          const result = await getAccountBalance(addr);

          for(let i=0 ; i<result.length ; i++){
            let item = {...result[i]}
            const currency = item.currency.length === 3 ? item.currency : convertHextoString(item.currency)
            try{
              const { data: tokenData } = await axios.get(`https://s1.xrplmeta.org/token/${currency}:${item.account}`)
              item['tokenImage'] = tokenData.meta.token.icon
              item['price'] = tokenData.metrics.price
            }catch(err){
              console.log('err')
            }
            tmp.push(item)           
          }
          setBalances(tmp);
        }

        xumm.runtime.xapp = true;
      } else {
        xumm.runtime.xapp = false;
      }
      setAddress(addr ?? '');
    });
  };

  const fetchBalances = useCallback(async () => {
    setLoadingBalance(true)
    const _crossmark = localStorage.getItem("crossmark");
    if (_crossmark) {
      const json = JSON.parse(_crossmark);
      try {
        let tmp = []
        if (balances.length === 0) {
          const result = await getAccountBalance(json.address);

          for(let i=0 ; i<result.length ; i++){
            let item = {...result[i]}
            if(item.currency ==='XRP'){
              item['tokenImage'] = "https://static-00.iconduck.com/assets.00/xrp-cryptocurrency-icon-2048x2048-2a0bicgj.png"
              item['price'] = 1
              tmp.push(item)
              continue
            }
            const currency = item.currency.length === 3 ? item.currency : convertHextoString(item.currency)

            try{
              const { data: tokenData } = await axios.get(`https://s1.xrplmeta.org/token/${currency}:${item.account}`)
              item['tokenImage'] = tokenData.meta.token.icon
              if(currency.toLowerCase() === 'xgo') item['tokenImage'] = XGOPng
              if(currency.toLowerCase() === 'xrdoge') item['tokenImage'] = XRDOGEPng
              if(currency.toLowerCase() === 'xrph') item['tokenImage'] = XRPHPng

              item['price'] = tokenData.metrics.price
              
            }catch(err){
              console.log('err')
            }
            tmp.push(item)
                
          }

          setBalances(tmp);
        }
      } catch (error) {
        setLoadingBalance(false)
        console.error('Failed to fetch balances for crossmark', error);
      }
    }

    const data = localStorage.getItem("XummPkceJwt");
    if (data) {
      const json = JSON.parse(data);
      try {
        let tmp = []
        if (balances.length === 0) {
          const result = await getAccountBalance(json.me.account);
          for(let i=0 ; i<result.length ; i++){
            let item = {...result[i]}
            if(item.currency ==='XRP'){
              item['tokenImage'] = "https://static-00.iconduck.com/assets.00/xrp-cryptocurrency-icon-2048x2048-2a0bicgj.png"
              item['price'] = 1
              tmp.push(item)
              continue
            }
            const currency = item.currency.length === 3 ? item.currency : convertHextoString(item.currency)
            try{
              const { data: tokenData } = await axios.get(`https://s1.xrplmeta.org/token/${currency}:${item.account}`)
              item['tokenImage'] = tokenData.meta.token.icon
              item['price'] = tokenData.metrics.price


             
            }catch(err){
              console.log('err')
            }
            tmp.push(item)
            
          }
          setBalances(tmp);
        }
      } catch (error) {
        setLoadingBalance(false)
        console.error('Failed to fetch balances for XummPkceJwt', error);
      }
    }

    setLoadingBalance(false)
  });

  const getFaucets = useCallback(async (address) => {
    try {
      const result = await getAccountBalance(address);
      setFaucets(result);
    } catch (error) {
      console.error('Failed to fetch balances for crossmark', error);
    }
  });

  const getCSRFToken = async () => {
    const { data } = await axios.get(`${process.env.REACT_APP_SERVER_URL}/csrf_token`, { withCredentials:true })
    const token = data.csrfToken
    console.log('datadsfasdfasdfasdf')
    window.sessionStorage.setItem('csrf', token);
  }

  const getJWT = async () => {
    const { data } = await axios.get(`${process.env.REACT_APP_SERVER_URL}/jwt`)
    const token = data
    window.sessionStorage.setItem('jwt', token);
  }

  useEffect(() => {
    // getCSRFToken()
    getJWT()
    xumm.on("error", (e) => {
      toast.error(e.message);
      console.log('error', e);
    });

    xumm.on('success', handleSuccess);

    sdk.on('signout', () => {
      localStorage.removeItem("crossmark");
      setAddress('');
      toast.success('Disconnected');
    })

    getProjects();
    getTokens();

    getFaucets("r4tVLAJRnJyHXbXBsCaSR16jYmg2KZ9gTJ");
    fetchBalances();

    return () => {
      xumm.off('success', handleSuccess);
    };
  }, []);

  useEffect(()=>{
    if(address?.length > 0) fetchBalances()
  },[address, fetchBalances])

  return (
    <WagmiProvider config={config}>
      <QueryClientProvider client={queryClient}>
        <AppContext.Provider value={{
          address,
          setAddress,
          projectsData,
          tokens,
          liquidity,
          setLiquidity,
          balances,
          setBalances,
          manageFromToken,
          manageToToken,
          faucets,
          loadingBalance
        }}>
          <div className="particle particle-1"></div>
          <div className="particle particle-2"></div>
          <div className="particle particle-3"></div>
          <div className="particle particle-4"></div>
          {children}
          <Toaster />
        </AppContext.Provider>
    </QueryClientProvider>
     </WagmiProvider>
  );
}

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <AppProvider>
      <Router>
        <Routes>
          <Route exact path="/projects" element={<Projects />} />
          <Route exact path="/swap" element={<Swap />} />
          <Route exact path="/liquidity" element={<Navigate replace to="/liquidity/all" />} />
          <Route exact path="/liquidity/all" element={<AllLiquidity />} />
          <Route exact path="/liquidity/add" element={<AddLiquidity />} />
          <Route exact path="/liquidity/:id" element={<Liquidity />} />
          <Route exact path="/bridge" element={<Bridge />} />
          <Route exact path="/apply" element={<Apply />} />
          <Route exact path="/faucet" element={<Faucet />} />
          <Route exact path="/leaderboard" element={<Leaderboard />} />
          <Route exact path="/detail/:id" element={<Detail />} />
          <Route path="/" element={<Home />} />
        </Routes>
        <Footer/>
      </Router>

    </AppProvider>
  </React.StrictMode>
);

reportWebVitals();
