import React, { useCallback, useEffect, useState, useContext } from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import LoadingIcon from "../../assets/images/loading.gif";
import LoadingQR from "../../assets/images/qr-loading.gif";
import CrossMarkWalletIcon from '../../assets/images/crossmarkwallet.png'
import XamanWalletIcon from '../../assets/images/xamanwallet.png'
import MetamaskIcon from '../../assets/images/MetamaskIcon.png'
import DiscordButtonIcon from '../../assets/images/DiscordButton.png'
import { LuCopy } from "react-icons/lu";
import { AppContext } from '../..';
import { formatAddress } from '../Global';
import Link from "../Link";
import './style.scss';
import { formatTransaction } from '../Global';
import CenterBox from '../CenterBox';

const Subtitle = ({ tx, subtitle }) => {
    return (
        <>
            {tx ?
                // <Link href={`https://testnet.xrpl.org/transactions/${subtitle}`}>
                <Link href={`https://xrpscan.com/tx/${subtitle}`}>
                    <p className='text-[20px] font-semibold'>Tx ID: {formatTransaction(subtitle)}</p>
                </Link>
                :
                <p  className='!text-[20px] !font-semibold'>{subtitle}</p>
            }
        </>
    );
}

export default function LoadingModal({ open, steps, currentStep, hasSummary=false, fromAsset=undefined, toAsset=undefined, type, finalStatus=undefined, data, close }) {
    const [activeStep, setActiveStep] = useState(0);
    const [isQRLoading, setIsQRLoading] = useState(true);
    const [xrpToEth, setXrpToEth] = useState(false)
    const [projectLink, setProjectLink] = useState('')

    const copyProjectLink = useCallback(() => {
        navigator.clipboard.writeText(projectLink)
    },[projectLink])

    const copyAddress = useCallback(()=>{
        navigator.clipboard.writeText(data?.token_issuer)
    },[data?.token_issuer])

    const handleImageLoad = () => {
        setIsQRLoading(false);
    };

    useEffect(() => {
        open && setIsQRLoading(true);
    }, [open])

    useEffect(() => {
        currentStep !== undefined && setActiveStep(currentStep);
    }, [currentStep]);

    useEffect(()=>{
        if(steps.length === 3){
            setXrpToEth(true)
        }else(
            setXrpToEth(false)
        )
    },[steps.length])

    useEffect(()=>{
        if(data?.id){
            setProjectLink(`/detail/${data.id}`)
        }else{
            setProjectLink('')
        }
    },[data?.id])
    // console.log('values', data)
    return (
        <Modal
            className="modal-wrapper"
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
                backdrop: {
                    timeout: 500,
                },
            }}
        >
            <Fade in={open}>
                {
                    finalStatus === undefined ?              
                    <Box className="modal-content-status">
                        {
                            hasSummary &&
                            <div className=''>
                                <p className='text-[25px] font-montserrat font-bold'>Bridging in progress...</p>
                                <p className='text-[20px] font-semibold'>Summary</p>
                                <p className='text-[20px] font-semibold'>{xrpToEth?"XRPL to ETH":"ETH to XRPL"}</p>
                                <div className='w-full flex justify-between text-[20px] font-semibold'>
                                    <p>Amount to send:</p>
                                    <p>
                                        { fromAsset.amount}
                                    </p>
                                </div>
                                <div className='w-full flex justify-between text-[20px] font-semibold'>
                                    <p>Amount to receive:</p>
                                    <p>
                                        { fromAsset.amount}
                                    </p>
                                </div>
                                <div className='w-full flex justify-between text-[20px] font-semibold'>
                                    <p>Fee:</p>
                                    <p>
                                        0.1%
                                    </p>
                                </div>
                                <CenterBox>
                                    <hr className='bg-black w-[90%]'/>
                                </CenterBox>
                            </div>
                        }
                        <Stepper activeStep={activeStep} orientation="vertical">
                            {steps.map((step, index) => (
                                <Step key={step.label}>
                                    <StepLabel
                                        sx={{ position: "relative", fontSize:"20px" }}
                                        optional={
                                            index === activeStep ? (
                                                <>
                                                    <img className='loading-indicator' src={LoadingIcon} alt="" />
                                                    <Subtitle tx={step.tx} subtitle={step.subtitle} />
                                                </>
                                            ) : index < activeStep && step.subtitle ?
                                                <Subtitle tx={step.tx} subtitle={step.subtitle} />
                                                : null
                                        }
                                    >
                                        <div>
                                        {step.label}
                                        </div>
                                        
                                    </StepLabel>
                                    <StepContent>
                                        {
                                            step.subMetaDesc ?
                                            <div className=''>
                                                <p className='text-[20px] font-semibold'>{step.description} </p>
                                                <div className='flex justify-between items-center'>
                                                    <img src={MetamaskIcon} alt='metamask' className='w-[100px]'/>
                                                    <p className='text-[16px] font-semibold ml-3'>{step.subMetaDesc}</p>
                                                </div>
                                            </div>
                                            :(
                                                step.subXamanDesc ?
                                                <div className=''>
                                                    <p className='text-[20px] font-semibold'>{step.description} </p>
                                                    <div className='flex justify-between items-center'>
                                                        <img src={XamanWalletIcon} alt='xaman' className='w-[100px]'/>
                                                        <p className='text-[16px] font-semibold ml-3'>{step.subXamanDesc}</p>
                                                    </div>
                                                </div>
                                                :<Box className="modal-box">
                                                {
                                                    fromAsset && step.confirmInWallet?
                                                    (
                                                        step.qr?
                                                        <div>
                                                            <img src={XamanWalletIcon}  alt=""/>
                                                        </div>
                                                        :
                                                        <div>
                                                            <img src={CrossMarkWalletIcon}  alt=""/>
                                                        </div>
                                                    )
                                                    :
                                                    (
                                                    step.qr ?
                                                    <Box className="modal-detail">
                                                        {isQRLoading &&
                                                            <Box className="qrcode">
                                                                <img src={LoadingQR} alt="" />
                                                            </Box>
                                                        }
                                                        <Box className="qr">
                                                            <img src={step.qr_link} alt="qr_code" onLoad={handleImageLoad} />
                                                        </Box>
                                                    </Box> :<></>
                                                        
                                                    )
                                                }
                
                                                <Typography className='description'>{step.description}</Typography>
                                            </Box>
                                            )
                                        
                                            
                                        }

                                    </StepContent>
                                </Step>
                            ))}
                        </Stepper>
                    </Box>
                    :
                    finalStatus === 'successed' ?
                    (
                        type === 'launch' ?
                        <Box className="modal-content-status !p-3 border-[4px] border-[#259731] outline-none">
                            <p className='text-center text-[40px] font-bold'>Congratulations!</p>
                            <p className='text-center text-[25px] font-semibold'>You have successfully created your presale</p>
                            <CenterBox>
                                <div className='flex space-x-4'>

                                    <div className='w-[250px] h-[250px] m-[20px]'>
                                        
                                        {
                                            data?.icon &&
                                            <img src={data.icon} alt='token logo' className='w-[250px] h-[250px] rounded-full '/>
                                        }
                                    </div>
                                 
                                    
                                    <div className='flex flex-col justify-center space-y-2 text-[30px] font-bold'>
                                        <p className='text-[25px] font-bold leading-8'>{data?.toke_nname}</p>
                                        <p className='text-[25px] font-bold leading-8'>{data?.token_symbol}</p>
                                        <p className='text-[20px] font-bold leading-7'>Supply: &nbsp; &nbsp; {data?.token_total_supply}</p>
                                        <p className='text-[20px] font-bold leading-7'>Soft Cap: &nbsp; &nbsp;   {Number(data?.soft_cap)/10**6}</p>
                                        <p className='text-[20px] font-bold leading-7'>Hard Cap: &nbsp; &nbsp;   {Number(data?.hard_cap)/10**6} </p>
                                        <div className='flex space-x-1'>
                                            <p className='text-[20px] font-bold leading-7'>Token Issuer: &nbsp;  {formatAddress(data?.token_issuer)} </p>
                                            <button onClick={copyAddress}><LuCopy size={20}/> </button>
                                        </div>
                                    </div>
                                </div>
                            </CenterBox>
                            <div>
                                <p className='text-[30px] font-bold'>What's Next?</p>
                                <p className='text-[20px] font-semibold'>
                                Share your projects pages link with your community, so they can participate when the presale begins.<br/><br/>If the Soft Cap is met, the presale is a success and you will receive the tokens and XRP delegated to you. If not your community will be able to claim a full refund.
                                </p>
                            </div>
                            <div className=' flex justify-center space-x-2 items-center mt-[10px]'>
                                <a href={projectLink} target='blank' className='text-white bg-black text-[20px] px-4 py-2 font-bold rounded-[12px]'>Project Page</a>
                                <button onClick={copyProjectLink}><LuCopy size={30}/> </button>
                            </div>
                        </Box>
                        : type === 'bridge' ? 
                            <Box className="modal-content-status !p-3 border-[4px] border-[#259731] outline-none">
                                <p className='text-center text-[40px] font-bold'>Bridge Successfull!</p>
                                <div className=''>
                                    <p className='text-[25px] font-montserrat font-bold'>Bridging in progress...</p>
                                    <p className='text-[20px] font-semibold'>Summary</p>
                                    <p className='text-[20px] font-semibold'>{xrpToEth?"XRPL to ETH":"ETH to XRPL"}</p>
                                    <div className='w-full flex justify-between text-[20px] font-semibold'>
                                        <p>Amount to send:</p>
                                        <p>
                                            { fromAsset.amount}
                                        </p>
                                    </div>
                                    <div className='w-full flex justify-between text-[20px] font-semibold'>
                                        <p>Amount to receive:</p>
                                        <p>
                                            { fromAsset.amount}
                                        </p>
                                    </div>
                                    <div className='w-full flex justify-between text-[20px] font-semibold'>
                                        <p>Fee:</p>
                                        <p>
                                            0.1%
                                        </p>
                                    </div>
                                    <CenterBox>
                                        <hr className='bg-black w-[90%] my-2'/>
                                    </CenterBox>
                                </div>
                                <div>
                                    {
                                        xrpToEth ?
                                            <p className='text-[25px] font-montserrat font-bold text-center'>The ETH has been sent to your <br/> Metamask wallet <br/> <br/>  <br/> <br/>  Thank you for using our platform <br/> <br/> </p>
                                        :
                                            <p className='text-[25px] font-montserrat font-bold text-center'> The bridged ETH has been sent to your <br/> XRP wallet  Thank you for using our platform <br/> <br/> </p>

                                    }
                                </div>
                                <div className=' flex justify-center space-x-2 items-center mt-[10px]'>
                                    <button onClick={close}  className='text-white bg-black text-[20px] px-4 py-2 font-bold rounded-[12px]'>Home</button>
                                </div>
                            </Box>
                        :<></>

                        
                        
                    ):
                    finalStatus === 'failed' ?                   
                    (
                        type === 'launch' | type === 'bridge' &&
                        <Box className="modal-content-status !p-3 border-[4px] border-[#9E3838] outline-none">
                            <p className='text-center text-[40px] font-bold'>Oopsie!</p>
                            <p className='text-center text-[25px] font-semibold'>An error has occured</p>
                            <p className='text-center text-[30px] font-semibold'>But dont worry, our devs will get on this and your 300 XRP fee will be refunded.</p>
                            <div>
                                <p className='text-[30px] font-bold'>What's Next?</p>
                                <p className='text-[20px] font-semibold'>
                                Please reach out to us on our Discord an open a ticket. (But feel free to message the mods too)<br/><br/>
                                If you could give the following information, would be helpful:<br/>
                                1. Error Code: <span className='text-[#9E3838]'>Failed to fetch</span> <br/>
                                2. On what step did it fail (1-4)<br/>
                                3. Your XRP address you used to connect<br/>
                                4. Token name, token logo, token symbol
                                </p>
                            </div>
                            <div className=' flex justify-center flex-col space-y-2 items-center mt-[10px]'>
                                <p className=' text-center text-[20px] font-semibold'>Please join and open a ticket with some of the information requested. Dont worry, you 300 XRP will be refunded fully!</p>
                                <a href='https://discord.com' target='blank'>
                                    <img src={DiscordButtonIcon} alt='discord' className='w-[140px]'/>
                                </a>
                            </div>
                        </Box>
                    ):<></>
                }

            </Fade>
        </Modal>
    );
}
