"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Session = void 0;
const typings = __importStar(require("@monorepo/typings/sdk"));
class Session {
    sdk;
    user;
    network;
    address;
    isOpen = false;
    lastPing;
    state = "unactive";
    responses = new Map();
    constructor(sdk) {
        this.sdk = sdk;
        this.sdk.on(typings.EVENTS.PING, this.handlePing);
        this.sdk.on(typings.EVENTS.RESPONSE, this.handleResponse);
        this.sdk.on(typings.EVENTS.USER_CHANGE, this.handleUserChange);
        this.sdk.on(typings.EVENTS.NETWORK_CHANGE, this.handleNetworkChange);
        this.sdk.on(typings.EVENTS.OPEN, this.handleOpen);
        this.sdk.on(typings.EVENTS.CLOSE, this.handleClose);
        this.sdk.on(typings.EVENTS.SIGNOUT, this.handleSignOut);
        this.sdk.on('detected', this.handleDetect);
    }
    handleDetect = async () => {
        this.network = (await this.sdk.api.awaitRequest({
            command: typings.COMMANDS.NETWORK,
        })).response.data.network;
        this.user = (await this.sdk.api.awaitRequest({
            command: typings.COMMANDS.USER,
        })).response.data.user;
        this.address = (await this.sdk.api.awaitRequest({
            command: typings.COMMANDS.ADDRESS,
        })).response.data.address;
        if (this.network && this.user && this.address)
            this.state = "active";
    };
    handlePing = () => (this.lastPing = Date.now());
    handleClose = () => (this.isOpen = false);
    handleOpen = () => (this.isOpen = true);
    handleSignOut = () => {
        this.state = "unactive";
        this.address = undefined;
        this.network = undefined;
        this.user = undefined;
    };
    handleNetworkChange = (network) => {
        this.network = network.network;
    };
    handleUserChange = (user) => {
        this.user = user.user;
        this.address = undefined;
    };
    handleResponse = (resp) => {
        if ('address' in resp.response.data) {
            this.state = "active";
            this.address = resp.response.data.address;
        }
        if ('network' in resp.response.data) {
            this.network = resp.response.data.network;
        }
        if ('user' in resp.response.data) {
            this.user = resp.response.data.user;
        }
        this.responses.set(resp.request.id, resp);
    };
}
exports.Session = Session;
Session.prototype['user'] = undefined;
Session.prototype['network'] = undefined;
Session.prototype['address'] = undefined;
